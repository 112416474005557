import { Info } from '@mui/icons-material'
import { Avatar, CardHeader, Chip, Divider, Grid } from '@mui/material'
import { blue } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import { fullAddressSelector, fullNameSelector } from 'app/store/selectors'
import AppointmentTime from 'components/organisms/AppointmentTime'
import { types } from 'constants/outcomes'
import { accountType } from 'constants/statuses'
import { tierToString } from 'constants/tiers'
import React from 'react'
import InstallationStatus from '../../status/InstallationStatus'
import {
  FullTypeChip,
  LiteTypeChip,
  StatusChip
} from '../../organisms/AccountChips'

const useStyles = makeStyles(theme => ({
  chips: {
    display: 'block',
    '& > *': {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1)
    }
  },
  info: {
    flexGrow: 1
  }
}))

const PropertyChip = ({ property = { id: '' } }) => {
  const { id } = property
  const formatted = `${id.substr(0, 3)}-${id.substr(3, 3)}-${id.substr(6, 4)}`
  return (
    <Chip
      label={formatted}
      sx={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        fontFamily: 'monospace',
        fontWeight: 600
      }}
    />
  )
}

/**
 * Header information about the appointment, including name, status etc.
 */
const AppointmentCardHeader = ({
  isActivating,
  installation,
  collection,
  mass
}) => {
  const classes = useStyles()

  const { appointment } = installation

  const installationTitle = (
    <Grid container spacing={0.5} sx={{ marginBottom: 0.5 }}>
      {appointment && (
        <Grid item>
          <AppointmentTime appointment={appointment} />
        </Grid>
      )}
      <Grid item>
        <Chip label={collection?.title} size='small' />
      </Grid>
    </Grid>
  )

  const installationSubheader = appointment && (
    <Grid container spacing={0.5}>
      <Grid item>
        <Chip
          label={appointment?.issue}
          size='small'
          sx={{ backgroundColor: blue[100] }}
        />
      </Grid>
      <Grid item>
        <Chip
          label={installation.appointment?.housingProviderName}
          size='small'
        />
      </Grid>
    </Grid>
  )

  const visits = () => {
    if (!installation.outcomes) return '1st visit'

    const count = installation.outcomes.length + 1
    const suffixes = {
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th'
    }
    const plural =
      suffixes[new Intl.PluralRules('en', { type: 'ordinal' }).select(count)]
    return `${count}${plural} visit`
  }

  return (
    <>
      <CardHeader
        action={
          <Avatar
            sx={{
              backgroundColor: 'secondary.dark',
              boxShadow: 1,
              fontSize: '1em',
              marginRight: 2,
              marginTop: 0.25
            }}
            variant='rounded'
          >
            {tierToString(installation.tier)}
          </Avatar>
        }
        avatar={<InstallationStatus installation={installation} />}
        subheader={installationSubheader}
        title={installationTitle}
      />

      <Divider />

      <CardHeader
        title={
          <Grid container spacing={1} direction='column'>
            <Grid item>
              <StatusChip
                isActivating={isActivating}
                account={installation?.account}
              />
              <PropertyChip property={installation?.account?.property} />
              {installation?.account?.type === accountType.HP_FULL ? (
                <FullTypeChip account={installation?.account} />
              ) : (
                <LiteTypeChip account={installation?.account} />
              )}
            </Grid>
            <Grid item>{fullNameSelector(installation)}</Grid>
          </Grid>
        }
        subheader={
          <>
            {fullAddressSelector(installation) && (
              <div>Address: {fullAddressSelector(installation)}</div>
            )}

            {installation?.account?.customer?.phone1 && (
              <div>
                Contact number: {installation?.account?.customer?.phone1}
              </div>
            )}

            {installation.outcome?.type !== types.COMPLETED && (
              <span className={classes.chips}>
                {installation?.outcomes?.length && (
                  <Chip
                    color='warning'
                    component='span'
                    label={visits(installation)}
                    size='small'
                  />
                )}
                {installation?.outcome?.notes && (
                  <Chip
                    color='primary'
                    component='span'
                    icon={<Info />}
                    label={installation.outcome.notes}
                    size='small'
                  />
                )}
              </span>
            )}
          </>
        }
      />

      <Divider />
    </>
  )
}

export default AppointmentCardHeader
