export const findPatchRollback = (state, uuid) =>
  state.patches.rollback.find(i => i.uuid === uuid)

export const findPatchRollbackIndex = (state, uuid) =>
  state.patches.rollback.findIndex(i => i.uuid === uuid)

export const findPatchEffect = (state, uuid) =>
  state.patches.effect.find(i => i.uuid === uuid)

export const findPatchEffectIndex = (state, uuid) =>
  state.patches.effect.findIndex(i => i.uuid === uuid)
