import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { Button, Grid } from '@mui/material'
import React from 'react'

/**
 * Provides navigation between a Stepper's steps.
 */
const StepperActions = ({
  activeStep = 0,
  stepCount = 0,
  onBack,
  onFinish,
  onNext,
  valid = true
}) => {
  const isFinalStep = activeStep === stepCount - 1

  return (
    <Grid
      alignItems='center'
      container
      justifyContent='space-between'
      spacing={1}
      sx={{ marginTop: 1 }}
    >
      <Grid container item sm={6} spacing={1}>
        <Grid item>
          <Button
            disabled={activeStep === 0}
            name='back'
            onClick={onBack}
            size='large'
            startIcon={<NavigateBefore />}
          >
            Back
          </Button>
        </Grid>

        <Grid item>
          <Button
            color='primary'
            disabled={!valid}
            endIcon={<NavigateNext />}
            name={isFinalStep ? 'save' : 'next'}
            onClick={isFinalStep ? onFinish : onNext}
            size='large'
            variant='contained'
          >
            {isFinalStep ? 'Save & test the device(s)' : 'Next'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StepperActions
