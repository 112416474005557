import { Container } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { MassTable } from '../../organisms'

/**
 * Shows a summary a mass installation.
 */
class MassPage extends React.Component {
  render () {
    return (
      <Container sx={{ mt: 3, mb: 3 }}>
        {this.props.mass && (
          <MassTable
            {...this.props}
            key={this.props.mass.id}
            mass={this.props.mass}
          />
        )}
      </Container>
    )
  }
}

/**
 * Some Redux magic to convert Redux state properties to standard Component
 * props. This makes it super-easy for us to work with data in a more intuitive,
 * presentational fashion.
 */
const mapStateToProps = (state, ownProps) => {
  const mass = state.masses[ownProps.match?.params.massId]
  return { mass: mass }
}

export default connect(mapStateToProps)(MassPage)
