import { getStatusColor, getStatusString } from 'constants/statuses'
import { Chip } from '@mui/material'

const StatusChip = ({ account, isActivating }) => {
  return (
    <Chip
      label={isActivating ? 'Activating...' : getStatusString(account?.status)}
      sx={{
        backgroundColor: getStatusColor(account?.status),
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRight: 0,
        color: 'white',
        fontWeight: 600,
        minWidth: 70,
        paddingLeft: '2px'
      }}
    />
  )
}

const LiteTypeChip = ({ account }) => {
  return (
    <Chip
      label='Lite'
      sx={{
        borderColor: getStatusColor(account?.status),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderRight: '1px solid',
        color: getStatusColor(account?.status),
        fontWeight: 600,
        '&:last-child': {
          borderTopRightRadius: '16px',
          borderBottomRightRadius: '16px'
        },
        paddingRight: '3px',
        width: 55
      }}
      variant='outlined'
    />
  )
}

const FullTypeChip = ({ account }) => {
  return (
    <Chip
      label='Full'
      sx={{
        backgroundColor: getStatusColor(account?.status),
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderLeft: 0,
        color: 'white',
        fontWeight: 600,
        paddingRight: '3px',
        width: 55
      }}
    />
  )
}

export { StatusChip, LiteTypeChip, FullTypeChip }
