import { ArrowBack } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { useHistory, useParams } from 'react-router'

/**
 * Back button which uses the context of the current route to decide where in
 * the structural hierarchy to go next.
 */
const BackButton = props => {
  const { collectionId, massId, installationId } = useParams()
  const history = useHistory()

  const handleBack = () => {
    let path = 'dashboard'
    if (installationId) {
      path = ['mass', massId, 'collection', collectionId].join('/')
    } else if (collectionId) {
      path = ['mass', massId, 'collection'].join('/')
    } else if (massId) {
      path = ['mass'].join('/')
    }
    history.push(`/${path}`)
  }

  return (
    <IconButton color='inherit' edge='start' onClick={handleBack} size='large'>
      <ArrowBack />
    </IconButton>
  )
}

export default BackButton
