import { LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import formatBytes from 'utils/formatBytes'

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
})

/**
 * Displays the current browser storage quota info. If the app has been
 * installed, then the quota is expected to persist and should not be prone to
 * Garbage Collection (GC).
 *
 * Makes use of {@link formatBytes}.
 */
const StorageStatus = props => {
  const [quota, setQuota] = React.useState(0)
  const [usage, setUsage] = React.useState(0)
  const [percentage, setPercentage] = React.useState(0)
  const classes = useStyles()

  const storage = navigator.storage

  storage?.estimate?.().then(estimate => {
    setQuota(estimate.quota)
    setUsage(estimate.usage)
    setPercentage(((100 / estimate.quota) * estimate.usage).toFixed(2))
  })

  return (
    <span className={classes.root}>
      <small>
        Using {formatBytes(usage)} of {formatBytes(quota)} ({percentage}%)
      </small>
      <LinearProgress variant='determinate' value={Math.ceil(percentage)} />
    </span>
  )
}

export default StorageStatus
