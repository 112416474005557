import { Phone, Router, Sensors, Tablet } from '@mui/icons-material'

/**
 * A list of all supported device types. Using an array to preserve order.
 */
export const devices = [
  {
    exclusive: true,
    icon: <Phone />,
    title: 'OkEachDay Phone',
    type: 0
  },
  { type: 2, exclusive: true, title: 'ContactHub', icon: <Router /> },
  {
    exclusive: true,
    icon: <Tablet />,
    title: 'Touchscreen',
    type: 3
  },
  { type: 4, title: 'Envosense Sensor', icon: <Sensors />, serialNumLength: 8 }
]

/**
 * Get the device, by referencing it's key
 */
export const getDevice = key => {
  return devices.find(d => d.type === key)
}

/**
 * Maps the devices to constants.
 */
export const deviceMap = {
  CONTACTHUB_TOUCH: getDevice(3),
  CONTACTHUB_CLASSIC: getDevice(2),
  ENVOSENSE_SENSOR: getDevice(4),
  OKEACHDAY_PHONE: getDevice(0)
}
