import { ExpandMore, Sync, SyncProblem, Sensors } from '@mui/icons-material'
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  Alert,
  Avatar,
  Button,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material'
import { clearEffects, patchInstallation, patchEnvosense } from 'app/store'
import React from 'react'
import { connect } from 'react-redux'
import styles from './SyncPage.module.css'

/**
 * Converts a date to a formatted GB local time.
 */
const timeFormat = new Intl.DateTimeFormat('en-GB', {
  dateStyle: 'medium',
  timeStyle: 'short'
})

const InstallText = ({ customer = { customer: {} }, ...rest }) => {
  const fullName = `${customer.title} ${customer.firstName} ${customer.surname}`
  return (
    <ListItemText primary={fullName} secondary={customer.address1} {...rest} />
  )
}

const getEnvosenseSerialNumbers = patchArray => {
  if (!patchArray || !Array.isArray(patchArray)) {
    return ''
  }

  const serialNumbers = patchArray.map(item => item.name).join(', ')
  return serialNumbers
}

class SyncPage extends React.Component {
  constructor (props) {
    super(props)
    this.handleRetry = this.handleRetry.bind(this)
    this.handleEffectsClear = this.handleEffectsClear.bind(this)
  }

  handleRetry (e, rollback) {
    if (rollback.type === 'Envosense') {
      this.props.patchEnvosense(
        rollback.effect,
        rollback.fullName,
        rollback.address,
        rollback.installationId,
        rollback.uuid
      )
    } else {
      this.props.patchInstallation(rollback.effect, rollback.uuid)
    }
  }

  handleEffectsClear () {
    this.props.clearEffects()
  }

  render () {
    const { patches } = this.props

    return (
      <Container sx={{ marginTop: 3 }} pt={3} pb={3}>
        {patches.rollback.length > 0 && (
          <Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              Failed to upload appointment data ({patches.rollback.length})
            </AccordionSummary>
            <Alert severity='error' variant='filled' sx={{ margin: 1 }}>
              There was a problem uploading data for{' '}
              <strong>
                {patches.rollback.length} appointment
                {patches.rollback.length > 1 && 's'}
              </strong>
              . You can retry sending the data here. If you continue to see
              problems, then try again when you are sure you have good internet
              connectivity.
            </Alert>

            <List>
              {this.props.patches.rollback.map(p => (
                <ListItem className={styles.myLayout} key={p.uuid}>
                  <ListItemAvatar className={styles.iconCol}>
                    <Avatar sx={{ bgcolor: 'warning.main' }}>
                      {p.type === 'Envosense' ? <Sensors /> : <SyncProblem />}
                    </Avatar>
                  </ListItemAvatar>
                  {p.type === 'Envosense' ? (
                    <ListItemText
                      primary={p.fullName + ' (Envosense)'}
                      secondary={p.address}
                      className={styles.installTextCol}
                      customer={'customer name placeholder'}
                    />
                  ) : (
                    <InstallText
                      className={styles.installTextCol}
                      customer={p.effect.account.customer}
                    />
                  )}
                  {p.createdAt && (
                    <ListItemText
                      primary='Last attempt'
                      secondary={timeFormat.format(new Date(p.createdAt))}
                      className={styles.lastAttemptCol}
                    />
                  )}
                  {p.type === 'Envosense' ? (
                    <div className={styles.failureCol}>
                      <ListItemText
                        primary='Serial Number(s)'
                        secondary={getEnvosenseSerialNumbers(p.effect)}
                      />
                    </div>
                  ) : (
                    <div className={styles.failureCol}>
                      <ListItemText
                        primary='Last failure reason'
                        secondary={p.effect.reason || 'Not reported'}
                      />
                    </div>
                  )}
                  <div className={styles.retryCol}>
                    <Button
                      color='primary'
                      disableElevation
                      onClick={e => this.handleRetry(e, p)}
                      variant='contained'
                    >
                      Retry
                    </Button>
                  </div>
                </ListItem>
              ))}
            </List>
          </Accordion>
        )}

        {patches.effect.length > 0 && (
          <Accordion TransitionProps={{ unmountOnExit: true }} defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Alert severity='info' variant='filled'>
                There {patches.effect.length > 1 ? 'are ' : 'is '}
                {patches.effect.length} appointment
                {patches.effect.length > 1 && 's'} being uploaded.
              </Alert>
            </AccordionSummary>

            <List>
              {this.props.patches.effect.map(p => (
                <ListItem className={styles.myLayout} key={p.uuid}>
                  <ListItemAvatar className={styles.iconCol}>
                    <Avatar sx={{ bgcolor: 'info.main' }}>
                      {p.type === 'Envosense' ? <Sensors /> : <Sync />}
                    </Avatar>
                  </ListItemAvatar>
                  {p.type === 'Envosense' ? (
                    <ListItemText
                      primary={p.fullName + ' (Envosense)'}
                      secondary={p.address}
                      className={styles.installTextCol}
                      customer={'customer name placeholder'}
                    />
                  ) : (
                    <InstallText
                      className={styles.installTextCol}
                      customer={p.effect.account.customer}
                    />
                  )}
                  {p.createdAt && (
                    <ListItemText
                      primary='Last attempt'
                      secondary={timeFormat.format(new Date(p.createdAt))}
                      className={styles.lastAttemptCol}
                    />
                  )}
                  {p?.type === 'Envosense' ? (
                    <ListItemText
                      primary='Serial Number(s)'
                      secondary={getEnvosenseSerialNumbers(p.effect)}
                      className={styles.failureCol}
                    />
                  ) : (
                    <ListItemText
                      primary='Last failure reason'
                      secondary={
                        p.effect.reason ? p.effect.reason : 'Not reported'
                      }
                      className={styles.failureCol}
                    />
                  )}
                </ListItem>
              ))}
            </List>
            <AccordionActions>
              <Button
                color='warning'
                variant='contained'
                onClick={this.handleEffectsClear}
              >
                Clear all
              </Button>
            </AccordionActions>
          </Accordion>
        )}

        <Accordion TransitionProps={{ unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Alert severity='info'>Appointment upload history</Alert>
          </AccordionSummary>
          <List>
            {this.props.patches.commit.map(p => (
              <ListItem key={p.uuid}>
                {p?.type === 'Envosense' ? (
                  <ListItemText
                    primary={p.fullName + ' (Envosense)'}
                    secondary={p.address}
                    className={styles.installTextCol}
                    customer={'customer name placeholder'}
                  />
                ) : (
                  <InstallText customer={p.effect.account.customer} />
                )}
              </ListItem>
            ))}
          </List>
        </Accordion>
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  patches: state.patches,
  envosense: state.envosense
})

const mapDispatchToProps = dispatch => ({
  clearEffects: () => dispatch(clearEffects()),
  patchInstallation: (effect, uuid) =>
    dispatch(patchInstallation(effect, uuid)),
  patchEnvosense: (effect, fullname, address, installationId, uuid) =>
    dispatch(patchEnvosense(effect, fullname, address, installationId, uuid))
})

export default connect(mapStateToProps, mapDispatchToProps)(SyncPage)
