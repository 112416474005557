import { Notes } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  TextField
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

/**
 * A dialog which provides for editing of free text notes. It also includes a
 * floating action button to trigger opening the dialog which will be displayed
 * over other content.
 */
const AccountNotesDialog = ({ installation, onChange }) => {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const formatPropertyId = () => {
    const id = installation?.account?.property?.id || ''
    return `${id.slice(0, 4)}-${id.slice(4, 7)}-${id.slice(7)}`
  }

  return (
    <Box sx={{ bottom: 32, position: 'fixed', right: 16, zIndex: 1 }}>
      <Fab color='primary' onClick={handleClickOpen} variant='extended'>
        <Notes sx={{ marginRight: 1 }} />
        Notes
      </Fab>

      <Dialog
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='md'
        onClose={handleClose}
        open={open}
      >
        <DialogTitle id='form-dialog-title'>Notes</DialogTitle>
        <DialogContent>
          <TextField
            disabled
            label='Property ID'
            margin='normal'
            value={formatPropertyId()}
            variant='outlined'
          />

          <TextField
            fullWidth
            label='General notes'
            margin='normal'
            multiline
            onChange={newValue => onChange('account.notes', newValue)}
            rows={6}
            value={installation?.account?.notes}
            variant='outlined'
          />

          <TextField
            fullWidth
            label='Medical notes'
            margin='normal'
            multiline
            onChange={newValue => onChange('account.medicalNotes', newValue)}
            rows={6}
            value={installation?.account?.medicalNotes}
            variant='outlined'
          />
        </DialogContent>
        <DialogActions>
          <Button color='primary' onClick={handleClose} variant='contained'>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default AccountNotesDialog
