export function sanitisePhoneNumber (value) {
  const removeRX = /\D/g
  /** UK number format */
  const ukSanitizeRX = /^(\d{0,5})(\d{0,3})(\d{0,3}()()$)/
  // Accepts UK numbers between 9 and 11 digits
  const ukValidationRX = /^[0-9]{5}\s[0-9]{3}\s[0-9]{0,3}$/

  /** Swedish M2M number format */
  const seSatnitizeRX =
    /^(?:0{0,2}46?)(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,3})$/
  const seValidationRX = /^[0-9]{6}\s[0-9]{3}\s[0-9]{2}\s[0-9]{3}\s[0-9]{3}$/

  let sanitizeRX = ukSanitizeRX
  let validationRX = ukValidationRX
  let prefix = ''

  switch (true) {
    case /^0046(.*)/.test(value):
    case /^46(.*)/.test(value):
    case /^\+46(.*)/.test(value):
      sanitizeRX = seSatnitizeRX
      validationRX = seValidationRX
      prefix = '0046'
      break
    default:
      break
  }

  const sanitizedValue = value
    /** Strip anything which isn't numeric */
    .replace(removeRX, '')
    /** Reformat to appropriate space-padded format */
    .replace(sanitizeRX, (match, p1, p2, p3, p4, p5) =>
      prefix.concat([p1, p2, p3, p4, p5].join(' '))
    )
    /** Just to be sure... */
    .trim()

  const cleanValue = sanitizedValue.replace(removeRX, '')

  /** Match `ddddd ddd ddd` */
  const invalid =
    /** If no value, then let a required validator deal with it */
    sanitizedValue.length ? !sanitizedValue.match(validationRX) : false

  return { invalid, sanitizedValue, cleanValue }
}

export function validatePhoneNumber (number) {
  if (!number) {
    return false
  }
  const valid = !sanitisePhoneNumber(number).invalid && number !== ''
  return valid
}
