import { Grid, Step, StepContent, TextField } from '@mui/material'
import StepperButton from 'components/steppers/StepperButton'
import React from 'react'
import RequiredTextField from '../../../inputs/RequiredTextField'

/**
 * Step to collect information about an installation property.
 */
const PropertyStep = ({
  activeStep,
  index,
  installation,
  onChange,
  onActiveStep,
  onValid,
  ...rest
}) => {
  React.useEffect(() => {
    const required = [
      installation?.account?.customer?.address1,
      installation?.account?.customer?.county,
      installation?.account?.customer?.town,
      installation?.account?.customer?.postcode
    ]

    if (activeStep === index) {
      const valid = !required.some(e => e === '')
      onValid(valid)
    }
  }, [activeStep, index, installation, onValid])

  return (
    <Step index={index} {...rest}>
      <StepperButton
        activeStep={activeStep}
        index={index}
        label='About the property'
        onActiveStep={onActiveStep}
      />

      <StepContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <RequiredTextField
              id='address1'
              label='Address line 1'
              onChange={val => onChange('account.customer.address1', val)}
              value={installation?.account?.customer.address1}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              id='address2'
              label='Address line 2'
              onChange={val => onChange('account.customer.address2', val)}
              value={installation?.account?.customer.address2}
              variant='outlined'
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <RequiredTextField
              id='town'
              label='Town/city'
              onChange={newValue => onChange('account.customer.town', newValue)}
              value={installation?.account?.customer.town}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <RequiredTextField
              id='county'
              label='County'
              onChange={val => onChange('account.customer.county', val)}
              value={installation?.account?.customer.county}
            />
          </Grid>

          <Grid item sm={4} xs={12}>
            <RequiredTextField
              id='postcode'
              label='Postcode'
              onChange={val => onChange('account.customer.postcode', val)}
              value={installation?.account?.customer.postcode}
            />
          </Grid>
        </Grid>
      </StepContent>
    </Step>
  )
}

export default PropertyStep
