const checkEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export function sanitiseEmailAddress (value) {
  const removeRX = /\s/g

  const cleanValue = value
    /** Strip anything which isn't numeric */
    .replace(removeRX, '')
    /** Just to be sure... */
    .trim()

  const invalid = checkEmail(cleanValue) ? false : true
  
  /** Blank values are valid */
  if (cleanValue === '') {
    return { invalid: false, cleanValue: '' }
  }

  return { invalid, cleanValue }
}
