import { Info } from '@mui/icons-material'
import {
  Chip,
  Grid,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListItem
} from '@mui/material'
import { addChange, patchInstallation } from 'app/store'
import InstallationStatus from 'components/status/InstallationStatus'
import { getOutcomeReasonString, reasons, types } from 'constants/outcomes'
import { accountType } from 'constants/statuses'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import AppointmentTime from '../AppointmentTime'
import CollectionTableRowAction from './CollectionTableRowAction'
import { FullTypeChip, LiteTypeChip, StatusChip } from '../AccountChips'

const NameContainerMaxWidth = '40%'

/**
 * Displays an individual install/appointment.
 */
const CollectionTableRow = ({
  collection,
  installation = { account: { customer: {} } },
  mass
}) => {
  /**
   * Destructure properties for convenience.
   */
  const {
    account: { customer },
    appointment
  } = installation

  /**
   * This is the Redux dispatcher. It can be used to dispatch actions.
   */
  const dispatch = useDispatch()

  /**
   * State reference to keep track of whether the user wants to submit a patch.
   */
  const [submitted, setSubmitted] = React.useState(false)

  /**
   * Determine if the installation should be patched. We do it here, with a
   * hook, rather than in the callback itself, as Redux actiosn are async and we
   * can't rely on properties being up-to-date there. This way, we make sure
   * that patching is triggered by a re-render.
   */
  React.useEffect(() => {
    if (submitted) {
      dispatch(patchInstallation(installation))
      setSubmitted(false)
    }
  }, [dispatch, installation, submitted])

  const address = `${customer.address1}, ${customer.postcode}`

  const name = `${customer.title} ${customer.firstName} ${customer.surname}`

  const visits = () => {
    const count = installation.outcomes.length + 1
    const suffixes = {
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th'
    }
    const plural =
      suffixes[new Intl.PluralRules('en', { type: 'ordinal' }).select(count)]
    return `${count}${plural} visit`
  }

  const url = installation.appointment
    ? `/mass/${mass?.id}/collection/${collection?.id}/engineering/${installation?.id}`
    : `/mass/${mass?.id}/collection/${collection?.id}/installation/${installation?.id}/step/0`

  /**
   * In order to ensure proper semantics, the variant for secondary text needs
   * to be a `<div/>` rather than a `<p/>`. Line height is set to the same as
   * the component default.
   */
  const secondaryTypographyProps = {
    lineHeight: 1.43,
    variant: 'div'
  }

  /**
   * Callback for marking an installation outcome as VACANT. Sets the outcome
   * and dispatches a patch.
   */
  const handleVacant = event => {
    const { id } = installation
    const vacant = reasons.completed.VACANT

    /**
     * Appointments store outcome props in a slightly different way, so they
     * need to be updated seperately.
     */
    dispatch(addChange('appointment.outcomeType', types.COMPLETED, id))
    dispatch(addChange('appointment.outcomeReason', vacant, id))
    dispatch(
      addChange('appointment.outcomeDetail', getOutcomeReasonString(vacant), id)
    )

    /**
     * Now, the standard object props are also updated.
     */
    dispatch(addChange('outcome.reason', vacant, id))
    dispatch(addChange('outcome.type', types.COMPLETED, id))
    dispatch(addChange('submitted', true, id))
    setSubmitted(true)
  }

  const AccountDetails = () => {
    return (
      <Grid container spacing={0.3}>
        <Grid item>
          <StatusChip isActivating={false} account={installation?.account} />
        </Grid>
        <Grid item>
          {installation?.account?.type === accountType.HP_FULL ? (
            <FullTypeChip account={installation?.account} />
          ) : (
            <LiteTypeChip account={installation?.account} />
          )}
        </Grid>
      </Grid>
    )
  }

  return (
    <ListItem
      alignItems='flex-start'
      divider
      disablePadding
      secondaryAction={<CollectionTableRowAction onVacant={handleVacant} />}
    >
      <ListItemButton component={RouterLink} to={url} dense>
        <ListItemAvatar>
          <InstallationStatus installation={installation} />
        </ListItemAvatar>

        <ListItemText
          secondaryTypographyProps={secondaryTypographyProps}
          primary={
            <Grid container alignItems='center' justifyContent='space-between'>
              <Grid item maxWidth={NameContainerMaxWidth}>
                <Grid container spacing={0.5} alignItems='center'>
                  <Grid item>
                    <strong>{name}</strong>
                  </Grid>
                  {!appointment &&
                    installation?.outcome?.type !== types.COMPLETED && (
                      <>
                        {installation?.outcomes?.length && (
                          <Grid item>
                            <Chip
                              color='warning'
                              component='span'
                              label={visits()}
                              size='small'
                            />
                          </Grid>
                        )}
                        {installation?.outcome?.notes && (
                          <Grid item>
                            <Chip
                              color='primary'
                              component='span'
                              icon={<Info />}
                              label={installation?.outcome?.notes}
                              size='small'
                            />
                          </Grid>
                        )}
                      </>
                    )}
                </Grid>
                <Grid item>{address}</Grid>
              </Grid>
              {installation?.outcome?.type !== types.COMPLETED && (
                  <Grid item>{AccountDetails()}</Grid>
                )}
            </Grid>
          }
          secondary={
            appointment && (
              <Grid container columnSpacing={0.5} alignItems='center'>
                <Grid item>
                  <AppointmentTime appointment={appointment} />
                </Grid>
                <Grid item>
                  <Chip label={appointment?.housingProviderName} size='small' />
                </Grid>
              </Grid>
            )
          }
        />
      </ListItemButton>
    </ListItem>
  )
}

export default CollectionTableRow
