import UUID from '../../utils/UUID'

/**
 * A standard for passing notifiable information through the application.
 */
class Notification {
  constructor ({
    action,
    callback = () => {},
    detail = '',
    severity = 'info',
    type = ''
  } = {}) {
    this.id = UUID()
    this.action = action
    this.callback = callback
    this.detail = detail
    this.severity = severity
    this.type = type
  }
}

export default Notification
