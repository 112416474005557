import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@mui/material'

class AppointmentRejectedDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = { notes: '', reason: 'Rejected' }
    this.baseState = this.state

    this.handleChange = this.handleChange.bind(this)
    this.handleExited = this.handleExited.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleExited () {
    this.reset()
  }

  handleSubmit () {
    const reason = 13
    const notes = `${this.state.reason}. ${this.state?.notes}`
    const type = 1
    this.props.onSubmit({ notes: notes, reason: reason, type: type })
    this.props.onClose()
  }

  reset () {
    this.setState(this.baseState)
  }

  render () {
    return (
      <Dialog
        aria-labelledby='postpone-dialog-title'
        fullWidth
        maxWidth='md'
        onClose={this.props.onClose}
        open={this.props.open}
        TransitionProps={{
          onExited: this.handleExited
        }}
      >
        <DialogTitle id='postpone-dialog-title'>Rejected install</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label='Notes'
            margin='normal'
            multiline
            name='notes'
            onChange={this.handleChange}
            rows={4}
            value={this.state?.notes}
            variant='outlined'
          />
        </DialogContent>

        <DialogActions>
          <Button
            color='primary'
            onClick={this.handleSubmit}
            variant='contained'
          >
            Confirm
          </Button>
          <Button onClick={this.props.onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AppointmentRejectedDialog
