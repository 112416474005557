import { TextField } from '@mui/material'
import React from 'react'
import RequiredTextField from '../RequiredTextField'
import {sanitiseEmailAddress} from './EmailFieldHelper'

/**
 * Text field which restricts value to Email format.
 *
 * @param {Object} props
 * @param {String} props.value
 */
const EmailTextField = props => {
  const { value = '', required = false } = props
  const removeRX = /\s/g

  let { invalid, cleanValue } = sanitiseEmailAddress(value)
  
  const handleChange = e => {
    e.target.value = e.target.value.replace(removeRX, '')
    props.onChange(e)
  }

  return (
    <>
      {(required && (
        <RequiredTextField
          error={invalid}
          helperText={invalid && 'Must be a valid Email address'}
          {...props}
          onChange={handleChange}
          value={cleanValue}
          type='email'
        />
      )) || (
        <TextField
          error={invalid}
          fullWidth
          helperText={invalid && 'Must be a valid Email address'}
          variant='outlined'
          {...props}
          onChange={handleChange}
          value={cleanValue}
          type='email'
        />
      )}
    </>
  )
}

export default EmailTextField
