import {
  Grid,
  IconButton,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import React from 'react'

const useStyles = makeStyles(theme => ({
  header: {
    ...theme.typography.body1
  }
}))

/**
 * Table header for appointment work lists.
 */
const AppointmentTableHead = (props, { mass = {} }) => {
  const classes = useStyles()

  return (
    <TableHead>
      <TableRow>
        <TableCell className={classes.header} colSpan={props.colspan}>
          <Grid alignItems='center' container justifyContent='space-between'>
            <Grid item>
              <IconButton aria-label='Previous day' disabled={!props.before} size="large">
                <NavigateBefore />
              </IconButton>
            </Grid>
            <Grid item>{props.mass.title}</Grid>
            <Grid item>
              <IconButton aria-label='Next day' disabled={!props.next} size="large">
                <NavigateNext />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default AppointmentTableHead
