/**
 * Populate state with normalised data derived from the fetched payload. Quite a
 * bit of wrangling done here, just to make it easier to do lookups in stateful
 * components.
 */
const populateWithFetch = (state, payload) => {
  const masses = {}
  const installationMap = {}

  if (!payload?.reason) {
    /* Compensate for payload being an obj prop or an unnamed array. */
    const normalizedPayload = payload?.masses || payload || []

    /**
     * Create a map for quick lookup of an installation within it's containing
     * objects. This can be used later on to easily find nested instances.
     */
    normalizedPayload.forEach(m => {
      m.collections.forEach((c, cidx) => {
        c.installations.forEach((i, iidx) => {
          /**
           * Make sure we restore any effects or rollbacks which might be in
           * progress.
           */
          const replacement = findReplacement(state, i)
          if (replacement) {
            m.collections[cidx].installations[iidx] = replacement
          }

          /**
           * Now add indexes to the flat map.
           */
          installationMap[i.id] = {
            collection: { id: c.id, idx: cidx },
            mass: { id: m.id },
            idx: iidx
          }
        })
      })
      masses[m.id] = m
    })
    state.installationMap = installationMap

    /** Do the same for masses. */
    state.masses = masses
    state.massesFlat = Object.entries(masses).map(([idx, m]) => ({
      id: m.id,
      title: m.title
    }))
  } else {
    state.error = payload?.reason
  }
}

/**
 * Search for a matching installation in either the rollback or effect queues.
 * We search effects first, as these are more likely to be the most recent
 * actions to have occurred and are actively being patched. Arrays are also
 * reversed so that, if there are multiple matches, we get the most recent.
 */
const findReplacement = (state, installation) => {
  const rollbacks = state.patches.rollback.reverse()
  const effects = state.patches.effect.reverse()

  const effectIndex = effects.findIndex(
    item => item.effect.id === installation.id
  )
  if (effectIndex > -1) return effects[effectIndex].effect

  const rollbackIndex = rollbacks.findIndex(
    item => item.effect.id === installation.id
  )
  if (rollbackIndex > -1) return rollbacks[rollbackIndex].effect
}

export { populateWithFetch }
