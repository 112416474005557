const appointmentSlots = {
  DEFAULT: 0,
  MORNING: 1,
  AFTERNOON: 2,
  FULLDAY: 3
}

const getAppointmentSlotString = slot => {
  switch (slot) {
    case appointmentSlots.MORNING:
      return 'Morning'
    case appointmentSlots.AFTERNOON:
      return 'Afternoon'
    case appointmentSlots.FULLDAY:
      return 'Full day'
    default:
      return 'Undefined'
  }
}

const appointmentTypes = {
  DEFAULT: 0,
  TIMED: 1,
  PERIOD: 2,
  TENTATIVE: 3
}

export { appointmentSlots, appointmentTypes, getAppointmentSlotString }
