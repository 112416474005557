import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import {
  addChange,
  fetchInstallation,
  getInstallation,
  patchInstallation
} from 'app/store'
import { installationUpgrade } from 'app/store/actions/installationUpgrade'
import { AppointmentCardHeader } from 'components/molecules'
import Banner from 'components/notifications/Banner'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

const OfflineContent = () => (
  <Grid container spacing={2} mb={2}>
    <Grid item>
      <Alert severity='warning' variant='filled'>
        <AlertTitle>You're currently working offline</AlertTitle>
        <p>
          While offline, it will not be possible to update account information
          or perform tests.
        </p>
        <p>
          However, all of the account information has been saved, and when you
          are online again, it will be sent through, and the account will be
          activated.
        </p>
      </Alert>
    </Grid>
  </Grid>
)

const RollbackContent = props => (
  <Grid container spacing={2} mb={2}>
    {props.envosenseStatus}
    <Grid item>
      <Alert severity='error' variant='filled'>
        <AlertTitle>There has been a problem</AlertTitle>
        <p>
          {props.reason ||
            "There's been an unexpected problem, which has prevented " +
              'the account information from being sent and for the account to be activated.'}
        </p>
        <p>
          All of the account information has been saved here, and you can try
          again later. If the problem persists, then please seek extra support.
        </p>
        <Button color='inherit' onClick={props.onClick} variant='outlined'>
          Retry
        </Button>
      </Alert>
    </Grid>
  </Grid>
)

const SubmittedContent = () => (
  <Grid
    container
    direction='column'
    spacing={2}
    sx={{
      marginBottom: 8,
      textAlign: 'center'
    }}
  >
    <Grid item sx={{ fontSize: 18, fontWeight: 'bold' }}>
      Sending account details.
    </Grid>
    <Grid item sx={{ fontSize: 18 }}>
      This could take a few moments, while the account is checked, details are
      updated, and some final checks are made.
    </Grid>
    <Grid item>
      <CircularProgress color='secondary' />
    </Grid>
    <Grid item>
      Please avoid navigating away from this page or refreshing. However, if
      you're still seeing this message after waiting a minute or longer, then
      you can safely navigate to a different appointment - the app will continue
      to try sending account details in the background.
    </Grid>
  </Grid>
)

const CompletedContent = props => (
  <Grid container direction='column' spacing={2}>
    {props.events && <Grid item>{props.events}</Grid>}
    {props.envosenseStatus}
    <Grid item>{props.summary}</Grid>
    <Grid item>
      <Alert severity='info' variant='filled'>
        <AlertTitle>Time to test the device</AlertTitle>
        During testing you can check the account again to make sure that is
        still active and receiving button presses.
      </Alert>
    </Grid>

    <Grid item sm={12}>
      <Button color='primary' onClick={props.onRefresh} variant='contained'>
        Check the account status again
      </Button>
    </Grid>
  </Grid>
)

/**
 * Installation page. Provides a wizard-like experience for capturing customer
 * data, and verifying the success of an install.
 */
class DevicePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      bannerColor: 'info',
      bannerLabel: 'Attempting to send data and activate the account...'
    }
    this.handleDone = this.handleDone.bind(this)
    this.handleRefresh = this.handleRefresh.bind(this)
    this.handleRetry = this.handleRetry.bind(this)
  }

  /**
   * The installation object, shorthand getter derived from props.
   */
  get installation () {
    return this?.props?.installation
  }

  get envosense () {
    return this?.props?.envosense
  }

  componentDidMount () {
    if (this.installation?.rolledback) {
      this.setRollbackLabel()
      return
    }

    if (this.installation?.activating) {
      this.setActivatingLabel()
      return
    }

    this.setCompletedLabel()
  }

  componentDidUpdate (prev) {
    if (
      this.installation?.rolledback &&
      this.installation.rolledback !== prev?.installation?.rolledback
    ) {
      this.setRollbackLabel()
      return
    }

    if (
      this.installation?.activating &&
      this.installation?.activating !== prev?.installation?.activating
    ) {
      this.setActivatingLabel()
      return
    }

    if (
      !this.installation?.activating &&
      this.installation?.activating !== prev?.installation?.activating
    ) {
      this.setCompletedLabel()
    }
  }

  handleDone () {
    this.props.history.push(
      [
        '/mass',
        this.props.mass.id,
        'collection',
        this.props.collection.id
      ].join('/')
    )
  }

  handleRefresh () {
    this.setActivatingLabel()
    this.props.addChange('submitted', true, this.installation.id)
    this.props.fetchInstallation(this.installation.id)
  }

  handleRetry () {
    this.props.addChange('submitted', true, this.installation.id)
    this.props.addChange('rolledback', false, this.installation.id)
    this.props.patchInstallation(
      this.installation,
      this.installation.rollbackUUID
    )
  }

  /**
   * Style object for box text styling
   */
  get boxStyle () {
    return { fontSize: 18, lineHeight: 1.65, marginBottom: 1 }
  }

  /**
   * Style object for highlighting inline spans.
   */
  get spanStyle () {
    return {
      backgroundColor: theme => alpha(theme.palette.secondary.main, 0.05),
      borderBottom: '2px solid',
      borderColor: theme => alpha(theme.palette.secondary.main, 0.15),
      color: 'secondary.main',
      paddingBottom: 0.25,
      paddingLeft: 0.5,
      paddingRight: 0.5
    }
  }

  get summary () {
    if (this.installation?.duplicateCli) {
      return (
        <Alert severity='warning' variant='filled'>
          <AlertTitle>Account setup complete with problem!</AlertTitle>
          The cli supplied is already in use on another account. Please contact
          the installer hotline.
        </Alert>
      )
    }
    if (this.installation?.scheduleUpdateFailure) {
      return (
        <Alert severity='warning' variant='filled'>
          <AlertTitle>Account setup complete with problem!</AlertTitle>
          Unable to apply the new schedule to the account. All other account
          details have been updated successfully. Please report this issue and
          update the contact schedule for this account via Pellonia.
        </Alert>
      )
    }
    return (
      <Alert variant='filled'>
        <AlertTitle>Account setup is complete!</AlertTitle>
        The account details have been saved, and the account is active with the
        number <strong>{this.formattedCli}</strong>.
      </Alert>
    )
  }

  get events () {
    return (
      this.installation?.account.events && (
        <Alert>
          A button press was received from the customer's device within the past{' '}
          <strong>5 minutes</strong>.
        </Alert>
      )
    )
  }

  get envosenseStatus () {

    if (this?.props?.rolledBackEnvosensePatches?.length > 0) {
      return (
        <Grid item>
          <Alert severity='error' variant='filled'>
            Failed to attach Envosense devices to the account
          </Alert>
        </Grid>
      )
    } else if (this?.props?.sendingEnvosensePatches?.length > 0) {
      return (
        <Grid item>
          <Alert severity='warning' variant='filled'>
            Attempting to attach Envosense devices to the account
          </Alert>
        </Grid>
      )
    }
    return null
  }

  get isActivating () {
    return this.installation.activating
  }

  get formattedCli () {
    const cli = this.installation?.account?.cli1
    if (cli.length < 17) {
      return [cli.substr(0, 5), cli.substr(5, 3), cli.substr(8)].join(' ')
    } else {
      return [
        cli.substr(0, 6),
        cli.substr(6, 3),
        cli.substr(9, 2),
        cli.substr(11, 3),
        cli.substr(14)
      ].join(' ')
    }
  }

  setCompletedLabel () {
    this.setState({
      bannerColor: 'success',
      bannerLabel:
        'The account data has been sent, if the account status was waiting, it will now be active'
    })
  }

  setActivatingLabel () {
    this.setState({
      bannerColor: 'info',
      bannerLabel: 'Fetching account information'
    })
  }

  setRollbackLabel () {
    this.setState({
      bannerColor: 'error',
      bannerLabel: 'There has been a problem with account activation'
    })
  }

  render () {
    if (!this.installation) return null

    return (
      <>
        <Container sx={{ mb: 11, mt: 3 }}>
          <Box mb={3}>
            <Card>
              <AppointmentCardHeader
                isActivating={this.isActivating}
                {...this.props}
              />

              {this.appointment && (
                <CardHeader
                  subheader={this.appointment?.issueDetails}
                  title={this.appointment?.issue}
                />
              )}

              <CardContent>
                {(!this.props.offline.online && <OfflineContent />) ||
                  (this.installation.rolledback && (
                    <RollbackContent
                      onClick={this.handleRetry}
                      reason={this.installation?.reason}
                      envosenseStatus={this.envosenseStatus}
                    />
                  )) ||
                  (this.installation?.activating && <SubmittedContent />) || (
                    <CompletedContent
                      events={this.events}
                      envosenseStatus={this.envosenseStatus}
                      onDone={this.handleDone}
                      onRefresh={this.handleRefresh}
                      summary={this.summary}
                    />
                  )}
                <Grid item sm={12} sx={{ mt: 2 }}>
                  <Grid container spacing={1} alignItems='center'>
                    <Grid item>
                      <Button
                        color='secondary'
                        onClick={this.handleDone}
                        variant='contained'
                      >
                        Back to your appointments
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
        <Banner color={this.state.bannerColor} label={this.state.bannerLabel} />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const mass = state.masses[ownProps.match.params.massId]
  if (!mass) return {}

  const collection = mass.collections.find(
    c => parseInt(c.id) === parseInt(ownProps.match.params.collectionId)
  )
  const installation = collection.installations.find(
    i => parseInt(i.id) === parseInt(ownProps.match.params.installationId)
  )


  const sendingEnvosensePatches = state.patches.effect.filter(
    element => element.installationId === installation.id
  )

  const rolledBackEnvosensePatches = state.patches.rollback.filter(
    element => element.installationId === installation.id
  )

  return {
    collection: collection,
    installation: installation,
    mass: mass,
    offline: state.offline,
    integrityCheck: state.integrityCheck,
    sendingEnvosensePatches: sendingEnvosensePatches,
    rolledBackEnvosensePatches: rolledBackEnvosensePatches
  }
}

const mapDispatchToProps = dispatch => ({
  addChange: (name, value, id) => dispatch(addChange(name, value, id)),
  installationUpgrade: effect => dispatch(installationUpgrade(effect)),
  getInstallation: id => dispatch(getInstallation(id)),
  fetchInstallation: id => dispatch(fetchInstallation(id)),
  patchInstallation: (effect, uuid) => dispatch(patchInstallation(effect, uuid))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DevicePage)
)
