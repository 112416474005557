import React, { useEffect } from 'react'
import { Notification, NotificationManager } from '../../app/managers'

const OnlineContext = React.createContext()

const OnlineProvider = ({ onChange, children }) => {
  const [online, setOnline] = React.useState(navigator.onLine)

  /**
   * Adds event listeners to the `window` object which will change the state of
   * the component, and in turn udpate any component referencing its' context.
   * Listeners also calls a callback added to `onChange`.
   */
  useEffect(() => {
    const handleOffline = () => {
      const notification = new Notification({
        detail: 'The device has gone offline',
        severity: 'warning'
      })
      setOnline(false)
      onChange && onChange();
      NotificationManager.addNotification(notification)
    }
    const handleOnline = () => {
      const notification = new Notification({
        detail: 'The device has gone online',
        severity: 'success'
      })
      setOnline(true)
      onChange && onChange();
      NotificationManager.addNotification(notification)
    }

    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [onChange])
  
  return (
    <OnlineContext.Provider value={online}>
      {children}
    </OnlineContext.Provider>
  )
}

/**
 * A functional hook which will derive context from the closest parent
 * `<OnlineProvider />`
 */
const useOnline = () => {
  return React.useContext(OnlineContext)
}

export { useOnline, OnlineProvider }
