import React, { useCallback } from 'react'
import { useSignIn } from 'providers/auth'
import GoogleLogin from 'providers/auth/GoogleLogin'
import rg4js from 'raygun4js'
import styles from './LoginPage.module.css'

/**
 * Provides a secure login page for the application.
 */
const LoginPage = () => {
  const signIn = useSignIn()
  const raygun = process.env.REACT_APP_RAYGUN_ENABLED === 'true'

  const onError = useCallback(error => {
    if (raygun) rg4js('send', { error })
    console.error('There was a problem logging in', error)
  }, [raygun])

  const onSuccess = useCallback(credential => {
    signIn(credential)
  }, [signIn])


  return (
    <div className={styles.main}>
      <GoogleLogin
        onError={onError}
        onSuccess={onSuccess}
      />
    </div>
  )
}

export default LoginPage
