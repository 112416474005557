import {
  AllInbox,
  Cloud,
  CloudDownload,
  Dashboard,
  ExitToApp,
  Inbox
} from '@mui/icons-material'
import {
  Avatar,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  SwipeableDrawer
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { StorageStatus } from 'components/status'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { OfflineOutboxStatus, OnlineStatus } from '../'
import { useAuth, useSignOut } from '../../../providers/auth'
import { useOnline } from 'providers/OnlineProvider'
import { useHasExpired } from 'providers/auth'

const drawerWidth = 270

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  grid: {
    height: '100%'
  },
  list: {
    width: drawerWidth
  }
}))

/**
 * AppDrawer is the primary means of navigation within the application. It
 * provides a swipeable app drawer.
 */
const NavDrawer = props => {
  const classes = useStyles()
  const { user } = useAuth()
  const signOut = useSignOut()
  const online = useOnline()
  const hasExpired = useHasExpired()

  const massesArr = useSelector(state => state?.massesFlat) || []

  const version = (
    <small>
      {process.env.NODE_ENV.toUpperCase()} {process.env.REACT_APP_VERSION}
    </small>
  )

  const handleSignOut = () => {
    signOut()
  }

  return (
    <div className={classes.root}>
      <SwipeableDrawer onOpen={() => {}} open={props.open || false} {...props}>
        <Grid
          className={classes.grid}
          container
          direction='column'
          justifyContent='space-between'
        >
          <Grid item>
            <List className={classes.list}>
              <ListItem disablePadding>
                <ListItemButton
                  component={RouterLink}
                  onClick={props.onClose}
                  to='/dashboard'
                >
                  <ListItemIcon>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText primary='Dashboard' />
                </ListItemButton>
              </ListItem>
            </List>

            {massesArr && (
              <List
                className={classes.list}
                subheader={<ListSubheader>Appointments</ListSubheader>}
              >
                <ListItem disablePadding>
                  <ListItemButton
                    component={RouterLink}
                    onClick={props.onClose}
                    to='/mass'
                  >
                    <ListItemIcon>
                      <AllInbox />
                    </ListItemIcon>
                    <ListItemText primary='All' />
                  </ListItemButton>
                </ListItem>

                {massesArr.map(mass => (
                  <ListItem disablePadding key={mass.id}>
                    <ListItemButton
                      component={RouterLink}
                      onClick={props.onClose}
                      to={`/mass/${mass.id}`}
                    >
                      <ListItemIcon>
                        <Inbox />
                      </ListItemIcon>
                      <ListItemText primary={mass.title} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}

            <Divider />

            <List>
              <ListItem disablePadding>
                <ListItemButton
                  component={RouterLink}
                  onClick={props.onClose}
                  to='/sync'
                >
                  <ListItemIcon>
                    <Cloud />
                  </ListItemIcon>
                  <ListItemText
                    primary='Your saved data'
                    secondary={<OfflineOutboxStatus />}
                  />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  component={RouterLink}
                  disabled={!online || hasExpired}
                  onClick={props.onClose}
                  to='/fetch'
                >
                  <ListItemIcon>
                    <CloudDownload />
                  </ListItemIcon>
                  <ListItemText
                    primary='Check for updates'
                    secondary='Download any new data'
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Grid>
          <Grid item>
            <Divider />

            <List className={classes.list}>
              <ListItem>
                <OnlineStatus />
              </ListItem>
              {user && (
                <ListItem >
                  <ListItemAvatar>
                    <Avatar
                      imgProps={{
                        referrerPolicy: 'no-referrer'
                      }}
                      src={user.picture}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={user.name} />
                </ListItem>
              )}

              <ListItem disablePadding>
                <ListItemButton onClick={handleSignOut}>
                  <ListItemIcon>
                    <ExitToApp />
                  </ListItemIcon>
                  <ListItemText>Log out</ListItemText>
                </ListItemButton>
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemText primary='App version' secondary={version} />
              </ListItem>

              <Divider />

              <ListItem>
                <ListItemText primary='Storage' secondary={<StorageStatus />} />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </SwipeableDrawer>
    </div>
  )
}

export default NavDrawer
