import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { LoginPage } from '../components/pages'

/**
 * Should only ever display the login page, so does some router magc to ensure
 * anything other than `/` is redirected.
 */
function UnauthenticatedApp () {
  return (
    <Switch>
      <Route exact path='/'>
        <LoginPage />
      </Route>
      <Route path='/*'>
        <Redirect to='/' />
      </Route>
    </Switch>
  )
}

export default UnauthenticatedApp
