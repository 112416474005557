import React, { useCallback, useEffect, useRef } from 'react'
import { Button } from '@mui/material'
import { useExpiring, useSignIn, useAuth, useSignOut, useHasExpired } from 'providers/auth'
import { Notification, NotificationManager } from 'app/managers'
import { useOnline } from 'providers/OnlineProvider'
import rg4js from 'raygun4js'

/**
 * A utility component to track the state of auth credentials and
 * trigger a Google one tap login whenever credentials have already, or are due
 * to expire.
 */
const AuthWatcher = () => {
  const expiring = useExpiring()
  const expired = useHasExpired()
  const signIn = useSignIn()
  const signOut = useSignOut()
  const { clientId, scriptLoadedSuccessfully } = useAuth()
  const raygun = process.env.REACT_APP_RAYGUN_ENABLED === 'true'
  const online = useOnline()
  const notificationId = useRef(null)

  /* Function to handle the user going to the login screen. */
  const goToLogin = useCallback(() => {
    NotificationManager.removeNotification(notificationId.current)
    signOut()
  }, [signOut])

  /* If the token has expired, show a notification and a button
   * that takes the user to the login screen. */
  useEffect(() => {
    if (!expired) return
    const expiredNotice = new Notification({
      action: (<Button color='inherit' onClick={goToLogin} size='small'>
          GO TO LOGIN
        </Button>),
      detail: 'Your login has expired. Please log in again.',
      severity: 'warning'})
    notificationId.current = expiredNotice.id
    NotificationManager.addNotification(expiredNotice)
  }, [expired, goToLogin, signOut])

  /* If our token is no longer expired, remove the notification. */
  useEffect(() => {
    if (expired) return
    if (!notificationId.current) return
    NotificationManager.removeNotification(notificationId.current)
  }, [expired])

  /* Handles the actual Google OneTap login prompt. */
  const googleAuth = useCallback(() => {
    const callback = response => {
      if (response.credential) {
        signIn(response.credential)
      } else {
        const { error } = response
        if (raygun) rg4js('send', { error })
      }
    }
    
    window.google.accounts.id.initialize({
      client_id: clientId,
      callback,
      auto_select: true,
      cancel_on_tap_outside: false
    })
    window.google.accounts.id.prompt()
  }, [clientId, signIn, raygun])

  /* If we're expiring, or expired, and online, and the script has loaded,
    * attempt to authenticate. */
  useEffect(() => {
    if (!expiring) return
    if (!online) return
    if (!scriptLoadedSuccessfully) return
    
    googleAuth()
  }, [clientId, online, expiring, scriptLoadedSuccessfully, googleAuth])

  return (
    <div data-expiring={expiring} data-testid='auth-watcher' />
  )
}

export default AuthWatcher
