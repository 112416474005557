import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import theme from 'theme/theme'
import ScrollToTop from '../components/molecules/ScrollToTop'
import NotificationContainer from '../components/organisms/NotificationContainer'
import { OnlineProvider } from './OnlineProvider'
import { GeocodeProvider } from './GeocodeProvider'

/**
 * There are a number of context providers, so they are gathered here and nested
 * in order of presendence.
 *
 * The `<ScrollToTop />` component is just a simple utility which ensures that
 * pages are scrolled up whenever the URL path changes.
 *
 * Finally, the `<NotifictionContainer />` is a container which listens for
 * notifications and then displays them in a queue.
 */
const AppProviders = ({ children }) => {
  return (
    <OnlineProvider>
      <GeocodeProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <ScrollToTop />
              <NotificationContainer />
              {children}
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </GeocodeProvider>
    </OnlineProvider>
  )
}

export default AppProviders
