import jwtDecode from 'jwt-decode'
import React, { useCallback } from 'react'
import { stringify } from 'utils/objectUtils'
import useLoadGsiScript from './useLoadGsiScript'
export const AuthContext = React.createContext({
  clientId: '',
  credential: null,
  scriptLoadedSuccessfully: false,
  tokenStatus: 'UNKNOWN',
  user: null
})
export const AuthDispatchContext = React.createContext({
  signIn: () => {},
  signOut: () => {}
})

/**
 * Storage key names.
 *
 * The user object and credential are stored locally for persistence across
 * sessions, and for contexts outside of React.
 */
const storage = {
  cred: '@alertacall/google/credential',
  user: '@alertacall/google/user'
}

/**
 * Shorthand for `window.localStorage`.
 */
const ls = window.localStorage

/**
 * Period of time to subtract from the token expiry time, so that tokens can
 * be reasonably refreshed before the fully expire.
 */
const expiryThreshold = process.env.REACT_APP_TOKEN_EXPIRY_THRESHOLD * 60 * 1000

/**
 * Period of time between token expiry checks.
 */
const checkInterval = 15 * 1000

/* Enum set for tracking token statuses as strings rather than booleans. */
const tokenStatuses = {
  EXPIRED: 'EXPIRED',
  EXPIRING: 'EXPIRING',
  OK: 'OK',
  UNKNOWN: 'UNKNOWN'
}

/**
 * Context provider which provides authentication context. Intended to be used
 * at a higher order to allow the use of `useAuth()` at any point in the
 * subtree.
 *
 * The credential object is in the form:
 * - https://developers.google.com/identity/gsi/web/reference/js-reference#credential
 */
export const AuthProvider = ({
  clientId,
  children
}) => {
  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = React.useState(false)
  const [user, setUser] = React.useState(JSON.parse(ls.getItem(storage.user)))
  const [tokenStatus, setTokenStatus] = React.useState(tokenStatuses.UNKNOWN)
  const [credential, setCredential] = React.useState(ls.getItem(storage.cred))

  const onScriptLoadSuccess = useCallback(() => {
    setScriptLoadedSuccessfully(true)
  }, [])

  const onScriptLoadError = useCallback(() => {
    setScriptLoadedSuccessfully(false)
  }, [])

  useLoadGsiScript({
    onScriptLoadSuccess,
    onScriptLoadError
  })

  /**
   * Callback to check whether a token is expiring.
   */
  const checkExpiry = React.useCallback(() => {
    /* If we've got no user, we can't check the expiry. */
    if (!user) return

    /* JS uses millisecond epochs, whereas the one generated server-side uses seconds
      * hence the multiplication here. */
    const expiry = user.exp * 1000

    /* Track the token status based on the expiry and threshold value */
    const delta = expiry - Date.now()
    if (delta < 0) {
      setTokenStatus(tokenStatuses.EXPIRED)
    } else if (delta < expiryThreshold) {
      setTokenStatus(tokenStatuses.EXPIRING)
    } else {
      setTokenStatus(tokenStatuses.OK)
    }
  }, [user])

  /**
   * Sets up a check on the expiry of the current credential. If it is going to
   * expire in less than 5 minutes, the state is updated.
   *
   * This gets run only once when the component mounts.
   */
  React.useEffect(() => {
    checkExpiry()
    const intervalId = setInterval(() => {
      checkExpiry()
    }, checkInterval)

    return () => clearInterval(intervalId)
  }, [checkExpiry])
  
  /**
   * Monitors the visibility change in addition to the interval check. This
   * allows the app to check the expiry of the token when the user returns to
   * the tab.
   */
  React.useEffect(() => {
    const visibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkExpiry()
      }
    }

    document.addEventListener("visibilitychange", visibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", visibilityChange);
    }
  }, [checkExpiry]);

  /**
   * Sign the user in with a google auth credential. The credential takes the
   * form of a signed JWT. When decoded, the JWT represents a standard user
   * object.
   *
   * The signin fails silently if the credential cannot be decoded. This could
   * happen if the token was truncated or tampered with in some way.
   *
   * Tracks user and credential objects in localStorage so they persist across 
   * sessions. There's also sometimes a need to access the object in vanilla JS 
   * (such as Reduxactions).
   * @param {*} credential
   */
  const signIn = useCallback(credential => {
    try {
      /* Decode the credential to get the user object. */
      const user = jwtDecode(credential)

      /* Set the credentials in state so things can depend on them. */
      setTokenStatus(tokenStatuses.OK)
      setCredential(credential)
      setUser(user)

      /* Also store the data in localStorage. */
      ls.setItem(storage.cred, credential)
      ls.setItem(storage.user, stringify(user))
    } catch (error) {
      console.error('Credential could not be decoded.')
    }
  }, [])

  /**
   * Sign the user out. Clears all stored credential and user data.
   */
  const signOut = useCallback(() => {
    /* Clear the state. */
    setTokenStatus(tokenStatuses.UNKNOWN)
    setCredential(null)
    setUser(null)
    /* Also remove the data from localStorage. */
    ls.removeItem(storage.cred)
    ls.removeItem(storage.user)
  }, [])

  const authState = React.useMemo(() => ({
    user,
    tokenStatus,
    credential,
    clientId,
    scriptLoadedSuccessfully
  }), [user, tokenStatus, credential, clientId, scriptLoadedSuccessfully])

  const dispatch = React.useMemo(() => ({
    signIn,
    signOut
  }), [signIn, signOut])

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthContext.Provider value={authState}>
        {children}
      </AuthContext.Provider>
    </AuthDispatchContext.Provider>
  )
}

/**
 * Hook which exposes current AuthProvier context.
 */
export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return context
}

/**
 * Hook which provides the `signIn` function.
 */
export const useSignIn = () => {
  const context = React.useContext(AuthDispatchContext)
  if (!context) {
    throw new Error('useSignIn must be used within AuthProvider')
  }
  return context.signIn
}

/**
 * Hook which provides the `signIn` function.
 */
export const useSignOut = () => {
  const context = React.useContext(AuthDispatchContext)
  if (!context) {
    throw new Error('useSignOut must be used within AuthProvider')
  }
  return context.signOut
}

/**
 * Hook to get whether the token is about to expire or has already expired 
 * and should be renewed.
 */
export const useExpiring = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useExpiring components must be used within AuthProvider')
  }
  return (context.tokenStatus === tokenStatuses.EXPIRING 
    || context.tokenStatus === tokenStatuses.EXPIRED)
}

/**
 * Hook to get whether the user is signed in.
 */
export const useIsSignedIn = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useIsSignedIn components must be used within AuthProvider')
  }
  return !!context.user
}

/**
 * Hook to get whether the token has expired and the user should be signed out.
 */
export const useHasExpired = () => {
  const context = React.useContext(AuthContext)
  if (!context) {
    throw new Error('useHasExpired components must be used within AuthProvider')
  }
  return (context.tokenStatus === tokenStatuses.EXPIRED)
}

/**
 * Removes the google user data from local storage.
 */
export const removeGoogleUser = () => {
  ls.removeItem(storage.user)
}

/**
 * A cheap way to access the localStorage value of `user`.
 * @returns {*}
 */
export const getGoogleUser = () => {
  try {
    return JSON.parse(ls.getItem(storage.user))
  } catch (error) {
    ls.removeItem(storage.user)
    if (error instanceof SyntaxError) {
      return null
    } else {
      throw error
    }
  }
}

/**
 * A cheap way to access the localStorage value of `googleCredential`.
 * @returns {string}
 */
export const getCredential = () => {
  return ls.getItem(storage.cred)
}
