import { Snackbar, Alert } from '@mui/material'

import React from 'react'

/**
 * Wrapper for the MUI Snackbar component which provides a simple interface for
 * showing snackbars with alerts.
 */
const SimpleSnackbar = ({ onExited, message, open, handleClose, ...rest }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={(!rest.action && 6000) || null}
      onClose={handleClose}
      TransitionProps={{
        onExited
      }}
    >
      <Alert {...rest} elevation={6} onClose={handleClose} variant='filled'>
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SimpleSnackbar
