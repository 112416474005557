import React from 'react'
import { Select, MenuItem } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'

const LocationSelect = ({ value, onChange, showRequired }) => {
  const locationOptions = [
    'Living room',
    'Kitchen',
    'Main Bedroom',
    'Spare Bedroom',
    'Bathroom',
    'Hallway',
    'Dining room'
  ]

  return (
    <FormControl fullWidth error={showRequired}>
      <InputLabel id='simple-select-label'>Location in the property *</InputLabel>
      <Select
        labelId='simple-select-label'
        label='Location in the property *'
        id='simple-select'
        value={value}
        name='roomLocation'
        onChange={onChange}
      >
        {locationOptions.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{showRequired && "This field is required"}</FormHelperText>
    </FormControl>
  )
}

export default LocationSelect
