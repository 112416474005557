import { Block, Event, Notes } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { addChange, patchInstallation } from 'app/store'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  AppointmentPostponeDialog,
  AppointmentRejectedDialog,
  ProviderNotesDialog
} from '../../organisms'

class AppointmentToolbar extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      openNotes: false,
      openPostpone: false,
      openRejected: false
    }

    this.handleNotes = this.handleNotes.bind(this)
    this.handlePostpone = this.handlePostpone.bind(this)
    this.handleSubmitOutcome = this.handleSubmitOutcome.bind(this)
    this.handleRejected = this.handleRejected.bind(this)
  }

  handleSubmitOutcome ({ notes, reason, type }) {
    this.props.addChange('outcome.notes', notes, this.props.installation.id)
    this.props.addChange('outcome.reason', reason, this.props.installation.id)
    this.props.addChange('outcome.type', type, this.props.installation.id)
    this.props.addChange('outcome.isInstall', true, this.props.installation.id)
    this.props.addChange('submitted', true, this.props.installation.id)
  }

  handleNotes () {
    this.setState({ openNotes: !this.state.openNotes })
  }

  handlePostpone () {
    this.setState({ openPostpone: !this.state.openPostpone })
  }

  handleRejected () {
    this.setState({ openRejected: !this.state.openRejected })
  }

  render () {
    return (
      <>
        <Tooltip title='Read housing provider notes' aria-label='notes'>
          <IconButton color='inherit' onClick={this.handleNotes} size='large'>
            <Notes />
          </IconButton>
        </Tooltip>

        <Tooltip aria-label='reschedule' title='Reschedule'>
          <IconButton
            color='inherit'
            onClick={this.handlePostpone}
            size='large'
          >
            <Event />
          </IconButton>
        </Tooltip>

        <Tooltip aria-label='Reject' title='Reject'>
          <IconButton
            color='inherit'
            edge='end'
            onClick={this.handleRejected}
            size='large'
          >
            <Block />
          </IconButton>
        </Tooltip>

        <AppointmentPostponeDialog
          aria-label='Reschedule'
          open={this.state.openPostpone}
          onClose={this.handlePostpone}
          onSubmit={this.handleSubmitOutcome}
        />

        <AppointmentRejectedDialog
          aria-label='Reject'
          open={this.state.openRejected}
          onClose={this.handleRejected}
          onSubmit={this.handleSubmitOutcome}
        />

        {this.props.installation && (
          <ProviderNotesDialog
            {...this.props}
            aria-label='notes'
            open={this.state.openNotes}
            onClose={this.handleNotes}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const mass = state.masses[ownProps.match.params.massId]
  if (!mass) return {}
  const collection = mass.collections.find(
    c => parseInt(c.id) === parseInt(ownProps.match.params.collectionId)
  )
  const installation = collection.installations.find(
    i => parseInt(i.id) === parseInt(ownProps.match.params.installationId)
  )

  return {
    collection: collection,
    installation: installation,
    mass: mass
  }
}

const mapDispatchToProps = dispatch => ({
  addChange: (name, value, id) => dispatch(addChange(name, value, id)),
  patchInstallation: effect =>
    dispatch(patchInstallation(effect))
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppointmentToolbar)
)
