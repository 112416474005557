const getEnvoSenseApiUrl = () => {
  const envosenseApi = process.env.REACT_APP_ENVOSENSE_API
  const envosenseApiRoot = process.env.REACT_APP_ENVOSENSE_API_ROOT
  const isTestOrigin = window.location.origin === process.env.REACT_APP_INSTALLER_APP_AZURE_URL

  return isTestOrigin ? envosenseApi : envosenseApiRoot
}

const getPelloniaApiUrl = () => {
  const apiRoot = process.env.REACT_APP_PELLONIA_API_ROOT
  const api = process.env.REACT_APP_PELLONIA_API
  const isTestOrigin = window.location.origin === process.env.REACT_APP_INSTALLER_APP_AZURE_URL
  return isTestOrigin ? api : apiRoot
}

export { getEnvoSenseApiUrl, getPelloniaApiUrl }
