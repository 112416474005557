import { Alert, Button, Step, StepContent } from '@mui/material'
import { DeviceForm } from 'components/steppers/forms'
import StepperButton from 'components/steppers/StepperButton'
import { deviceMap, devices } from 'constants/devices'
import { tiers } from 'constants/tiers'
import React from 'react'
import AddDevice from './AddDevice'
import { validatePhoneNumber } from 'components/inputs/PhoneTextField/PhoneFieldHelper'

/**
 * Step to collect information about the installed device.
 */
const DeviceStep = ({
  activeStep,
  index,
  installation,
  onActiveStep,
  onChange = () => {},
  onAdd,
  onRemove,
  onSelected,
  onValid,
  showCheck = false,
  ...rest
}) => {
  const [selected, setSelected] = React.useState(null)
  const [envosenseUniqueHelper, setEnvosenseUniqueHelper] = React.useState('')

  const arrayHasDuplicates = arr => arr.length !== new Set(arr).size

  React.useEffect(() => {
    if (activeStep === index) {
      if (installation?.account?.devices?.length > 0) {
        // Decides whether the next button on this step is enabled
        const validCheck = []
        const envosenseDevices = installation?.account?.devices?.filter(
          d => d.type === deviceMap.ENVOSENSE_SENSOR.type
        )

        setEnvosenseUniqueHelper('')
        if (
          envosenseDevices?.length &&
          arrayHasDuplicates(envosenseDevices.map(d => d.serialNumber))
        ) {
          setEnvosenseUniqueHelper('Serial numbers must be unique')
          onValid(false)
          return
        }

        // Cannot progress if the only devices added are Envosense sensors
        if (
          envosenseDevices?.length === installation?.account?.devices?.length
        ) {
          onValid(false)
          return
        }

        for (let i = 0; i < installation?.account?.devices?.length; i++) {
          switch (installation?.account?.devices[i].type) {
            case deviceMap.OKEACHDAY_PHONE.type:
              validCheck.push(
                validatePhoneNumber(installation?.account?.devices[i]?.phone)
              )
              break
            case deviceMap.CONTACTHUB_TOUCH.type:
              const touchscreenVerifyEqual =
                installation?.account?.devices[i]?.verifyMsisdn ===
                installation?.account?.devices[i]?.msisdn
              validCheck.push(
                validatePhoneNumber(
                  installation?.account?.devices[i]?.msisdn
                ) && touchscreenVerifyEqual
              )
              break
            case deviceMap.CONTACTHUB_CLASSIC.type:
              validCheck.push(
                validatePhoneNumber(installation?.account?.devices[i]?.msisdn)
              )
              break
            case deviceMap.ENVOSENSE_SENSOR.type:
              const envoVerifyEqual =
                installation?.account?.devices[i]?.serialNumber ===
                installation?.account?.devices[i]?.verifySerialNumber
              validCheck.push(
                installation?.account?.devices[i]?.serialNumber?.length ===
                  deviceMap.ENVOSENSE_SENSOR.serialNumLength && envoVerifyEqual
              )
              validCheck.push(
                installation?.account?.devices[i]?.roomLocation?.length > 0
              )
              break
            default:
              validCheck.push(false)
          }
        }

        onValid(validCheck.every(v => v === true))
      } else {
        onValid(false)
      }
    }
  }, [activeStep, index, installation, onValid])

  /**
   * Gets a deep copy of the current devices. Note that we have to be careful to
   * clone the objects, as the state is immutable.
   * @type Array
   */
  const currDevices = installation?.account?.devices
    ? installation.account.devices.map(d => ({ ...d }))
    : []

  /**
   * Gets a deep copy of the removed devices. Note that we have to be careful to
   * clone the objects, as the state is immutable.
   * @type Array
   */
  const removedDevices = installation?.removedDevices
    ? installation.removedDevices.map(d => ({ ...d }))
    : []

  /**
   * Some device types are exclusive. If just installing Envosense, then show
   * sensors exclusively.
   */
  const exclusive =
    currDevices.some(d =>
      [
        deviceMap.CONTACTHUB_TOUCH.type,
        deviceMap.CONTACTHUB_CLASSIC.type,
        deviceMap.OKEACHDAY_PHONE.type
      ].includes(d.type)
    ) || installation?.tier === tiers.ENVOSENSE

  /**
   * Adds a new device to the devices property.
   */
  const handleAdd = event => {
    const device = devices.find(d => d.type === event.target.value)
    const { icon, ...deviceDetails } = device
    currDevices.push({ ...deviceDetails, isNew: true})
    onChange('account.devices', { target: { value: currDevices } })
    onAdd?.(event)
  }

  /**
   * Make a change to the property of a device.
   */
  const handleChange = ({ index, prop, value }) => {
    const device = currDevices[index]
    if (!device) return
    device[prop] = value
    onChange?.('account.devices', { target: { value: currDevices } })
  }

  /**
   * Remove a device from the devices property. If it is a currently installed
   * device (indicated by it having an `id` property), then is also gets stored
   * in a `removedDevices` property.
   */
  const handleRemove = (event, index) => {
    const removed = currDevices.splice(index, 1)
    onChange?.('account.devices', { target: { value: currDevices } })
    onRemove?.(event)

    if (removed[0].id) {
      removedDevices.push(...removed)
      onChange?.('removedDevices', {
        target: { value: removedDevices }
      })
    }
  }

  /**
   * Change state when a form has focus.
   */
  const handleSelected = index => {
    setSelected(index)
    onSelected?.(index)
  }

  /**
   * Undo the removal of all devices.
   */
  const handleUndo = () => {
    currDevices.unshift(...removedDevices)
    onChange?.('account.devices', { target: { value: currDevices } })
    onChange?.('removedDevices', { target: { value: [] } })
  }

  return (
    <Step index={index} {...rest}>
      <StepperButton
        activeStep={activeStep}
        index={index}
        label='Device setup'
        onActiveStep={onActiveStep}
      />
      <StepContent>
        {installation?.removedDevices?.length > 0 && (
          <Alert
            action={
              <Button color='inherit' onClick={handleUndo} size='small'>
                UNDO
              </Button>
            }
            severity='info'
            sx={{ marginBottom: 1 }}
          >
            One or more devices have been removed.
          </Alert>
        )}

        {installation?.account?.devices?.map((device, index) => (
          <DeviceForm
            device={device}
            index={index}
            key={index}
            selected={index === selected}
            onChange={handleChange}
            onRemove={handleRemove}
            envosenseUniqueHelper={envosenseUniqueHelper}
            onSelected={handleSelected}
          />
        ))}

        <AddDevice exclusive={exclusive} onSelect={handleAdd} />
      </StepContent>
    </Step>
  )
}

export default DeviceStep
