import { FormControlLabel, Grid, Switch, TextField } from '@mui/material'
import { PhoneTextField } from 'components/inputs'

/**
 *
 */
const PhoneForm = ({ device, index, onChange }) => {
  return (
    <Grid container item spacing={2}>
      <Grid item sm={6} xs={12}>
        <PhoneTextField
          id={`phone${index}`}
          name='phone'
          label='Phone number'
          onChange={onChange}
          required
          value={device?.phone || ''}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <FormControlLabel
          control={<Switch name='checkedA' color='primary' />}
          id={`reprogrammedPhone${index}`}
          name='reprogrammedPhone'
          label='Is this a reprogrammed phone?'
          checked={device?.reprogrammedPhone}
          onChange={onChange}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          helperText='E.g. BT, TalkTalk'
          id={`landlineProvider${index}`}
          name='landlineProvider'
          label='Landline provider'
          onChange={onChange}
          value={device?.landlineProvider || ''}
          variant='outlined'
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          helperText='E.g. O2, Vodafone'
          id={`personalMobileProvider${index}`}
          name='personalMobileProvider'
          label='Personal mobile provider'
          onChange={onChange}
          value={device?.personalMobileProvider || ''}
          variant='outlined'
        />
      </Grid>
    </Grid>
  )
}

export default PhoneForm
