import { createReducer } from '@reduxjs/toolkit'
import { setPath } from 'utils/objectUtils'
import {
  findPatchEffect,
  findPatchEffectIndex,
  findPatchRollback,
  findPatchRollbackIndex
} from '../selectors'
import initialState from './initialState'

/**
 * Reducer which handles actions for individual installations.
 */
export const installationReducer = createReducer(initialState, builder => {
  builder
    /**
     * Update an installation when a change occurs to one of it's properties.
     */
    .addCase('installation/change', (state, action) => {
      const i = state.installationMap[action.payload.id]
      if (!i) return
      const mass = state.masses[i.mass.id]
      const collection = mass.collections[i.collection.idx]
      const installation = collection.installations[i.idx]
      setPath(installation, action.payload.name, action.payload.value)
    })

    /**
     * Ensure we always stash a version of every patch to ensure data is
     * preserved.
     */
    .addCase('installation/patch', (state, action) => {
      const uuid = action.payload.uuid

      /**
       * Make sure there are no orphaned patches, by filtering out this one if
       * it already exists.
       */
      state.patches.effect = state.patches.effect.filter(
        element => element.uuid !== uuid
      )

      /**
       * If the installation already exists on the rollback queue, we extract it
       * and place it onto the effects queue. Prevents us ending up with
       * orphaned duplicates.
       *
       * If the installation isn't on the rollback queue, then a new one is
       * created.
       */
      const item = findPatchRollback(state, uuid)
        ? state.patches.rollback
            .splice(findPatchRollbackIndex(state, uuid), 1)
            .at(0)
        : {
            createdAt: new Date().toISOString(),
            effect: action.payload.effect,
            uuid
          }

      /**
       * We really shouldn't be setting the `activating` property here, especially against the masses object
       * rather than the specific patch. This change fixes a bug and keeps the code inline with how it
       * originally worked but the whole system of setting status flags should be overhauled at some point.
       */
      const meta = state.installationMap[action.payload.effect.id]
      const installation =
        state.masses[meta.mass.id].collections[meta.collection.idx]
          .installations[meta.idx]
      const updatedInstallation = {
        ...installation,
        activating: true
      }
      state.masses[meta.mass.id].collections[meta.collection.idx].installations[
        meta.idx
      ] = updatedInstallation

      /**
       * Put the item on the effect queue.
       */
      state.patches.effect.push(item)
    })

    /**
     * If a patch has been committed, then everything is fine and in sync.
     *
     * 🔔 It's important to rememer that the action payload here will actually
     * be the request response sent back from the API. Ensures we are always
     * showing the most up-to-date state from the ultimate source of truth.
     */
    .addCase('installation/patch/commit', (state, action) => {
      const uuid = action.meta.uuid

      /**
       * Make sure we pull the effect from the effect queue, if it currently
       * lives there (likely), and then place onto the final commit queue.
       */
      if (findPatchEffect(state, uuid)) {
        const effect = state.patches.effect.splice(
          findPatchEffectIndex(state, uuid),
          1
        )
        state.patches.commit.push({
          createdAt: new Date().toISOString(),
          effect: effect.at(0).effect,
          uuid
        })
      }

      /**
       * Update our state with the new instance form the API. Should there be
       * any further patches, we can keep track of the single source of truth.
       * Set the `activating` property to `false` so DevicePage.js knows to
       * render the completed content.
       */
      action.payload.activating = false
      const meta = state.installationMap[action.payload.id]
      state.masses[meta.mass.id].collections[meta.collection.idx].installations[
        meta.idx
      ] = action.payload
    })

    /**
     * If a patch has failed terminally, and been discarded, then we're
     * essentially rolling back state and need to track it.
     */
    .addCase('installation/patch/rollback', (state, action) => {
      const uuid = action.meta.uuid

      /**
       * The patch should previously exist on the effect queue, so we grab it
       * from there, set some additional state to indicate rollback status, and
       * then place on the rollback queue.
       */
      if (findPatchEffect(state, uuid)) {
        const rollback = state.patches.effect.splice(
          findPatchEffectIndex(state, uuid),
          1
        )

        const installation = rollback.at(0).effect
        installation.rolledback = true
        installation.rollbackUUID = uuid
        installation.reason = action.payload?.message ?? null
        installation.activating = false
        installation.submitted = false
        state.patches.rollback.push({
          createdAt: new Date().toISOString(),
          effect: installation,
          uuid
        })

        /**
         * Update instance state, so that we can track the rollback status of
         * individual installations. They will have `rolledback` and
         * `rolledbackUUID` properties.
         */

        const meta = state.installationMap[installation.id]
        if (meta) {
          state.masses[meta.mass.id].collections[
            meta.collection.idx
          ].installations[meta.idx] = installation
        }
      }
    })

    /**
     * After successfully fetching an installation, update the nested state, so
     * that we are observing the most up-to-date instance from the API.
     */
    .addCase('installation/fetch/commit', (state, action) => {
      const meta = state.installationMap[action.payload.id]

      state.masses[meta.mass.id].collections[meta.collection.idx].installations[
        meta.idx
      ] = action.payload
    })

    /**
     * Provides the ability to manually upgrade an installation instance.
     * Generally used when preforming a straightforward network request to get
     * the latest state of an installation entity, and then checking it's
     * integrity.
     */
    .addCase('installation/upgrade', (state, action) => {
      const meta = state.installationMap[action.payload.effect.id]

      state.masses[meta.mass.id].collections[meta.collection.idx].installations[
        meta.idx
      ] = action.payload.effect

      /**
       * We've probably arrived here while waiting for integrity to be checked,
       * so set that global state property to `null`.
       */
      state.integrityCheck = null
    })

    /**
     * Be a good citizen and at the very least, pass back the state.
     */
    .addDefaultCase((state, action) => state)
})
