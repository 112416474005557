import React from 'react'
import { MenuItem, TextField } from '@mui/material'

/**
 * A user-friendly input comopnent for selecting a gender.
 */
const GenderSelect = props => {
  const genders = [
    ['Female', '0'],
    ['Male', '1'],
    ['N/A', '2']
  ]

  return (
    <TextField
      fullWidth
      label='Gender'
      select
      {...props}
      value={props.value || ''}
    >
      {genders.map(gender => (
        <MenuItem key={gender[1]} value={gender[1]}>
          {gender[0]}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default GenderSelect
