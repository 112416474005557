import { createTheme } from '@mui/material/styles'
/**
 * Provides a theme which can be used throughout the app. The configuration
 * object passed to `createMuiTheme()` can be used to override and extend the
 * default Material UI theme.
 *
 * Colour palette is derived from Alertacall brand colours. Palette available at
 * https://bit.ly/3hLHTeD
 */
export default createTheme({
  palette: {
    background: {
      default: '#f5f5f5'
    },
    primary: {
      contrastText: '#fff',
      dark: '#011524',
      light: '#546578',
      main: '#2a3b4c'
    },
    secondary: {
      contrastText: '#fff',
      dark: '#db2b72',
      light: '#ff66a0',
      main: '#a40047'
    }
  },
  overrides: {
    MuiStepLabel: {
      label: {
        // fontWeight: '900'
      },
      '&.Mui-active': {
        fontWeight: '900 !important'
      }
    }
  }
})
