import { useEffect, useRef } from 'react'
import { useAuth } from './AuthProvider'

const containerHeightMap = { large: 40, medium: 32, small: 20 }
const LOGIN_WIDTH = 250

/**
 * Displays a Google login button.
 */
const GoogleLogin = ({
  onSuccess,
  onError,
  type = 'standard',
  theme = 'outline',
  size = 'large'
}) => {
  const btnContainerRef = useRef(null)
  const { clientId, scriptLoadedSuccessfully } = useAuth()

  useEffect(() => {
    if (!scriptLoadedSuccessfully) return

    window.google.accounts.id.initialize({
      client_id: clientId,
      callback: response => {
        if (response.credential) {
          onSuccess(response.credential)
        } else {
          onError(response.error)
        }
      }
    })

    window.google.accounts.id.renderButton(btnContainerRef.current, {
      type,
      theme,
      size,
      width: LOGIN_WIDTH
    })
  }, [clientId, scriptLoadedSuccessfully, type, theme, size, onSuccess, onError])

  return (
    <div ref={btnContainerRef} style={{ height: containerHeightMap[size] }} />
  )
}

export default GoogleLogin
