import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid
} from '@mui/material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { OfflineOutboxStatus } from '../../molecules'
import { useSelector } from 'react-redux'
import { useOnline } from 'providers/OnlineProvider'
import { useHasExpired, useSignOut } from 'providers/auth'

const DashboardPage = () => {
  const installationMap = useSelector(state => state.installationMap);
  const patches = useSelector(state => state.patches);
  const expired = useHasExpired()
  const online = useOnline()
  const signOut = useSignOut()

  return (
    <Container sx={{ mt: 3, mb: 3 }}>
      <Grid container spacing={3}>
        {!online && (
          <Grid item xs={12}>
            <Alert severity='warning' variant='filled'>
              <AlertTitle>You're currently offline.</AlertTitle>
              Any changes you make to an appointment will be stored safely,
              and once you are online again, the data you've saved will be
              uploaded automatically.
            </Alert>
          </Grid>
        )}

        {expired && (
          <Grid item xs={12}>
            <Alert severity='warning' variant='filled'>
              <AlertTitle>Your login has Expired.</AlertTitle>
              <p>
                You can navigate and use the app, but any changes you make will
                not be able to be submitted to Pellonia. Please log in again to
                continue using the app normally.
              </p>
              <Button
                color='inherit'
                onClick={signOut}
                variant='outlined'
              >
                Go to Login
              </Button>
            </Alert>
          </Grid>
        )}

        {patches.effect.length > 0 && (
          <Grid item xs={12}>
            <Alert severity='info' variant='filled'>
              <AlertTitle>Updates are waiting to be sent</AlertTitle>
              <p>
                {patches.effect.length} appointment
                {patches.effect.length > 1 && 's'} waiting to be uploaded.
                This isn't an indication of a problem. Uploads will happen
                automatically when you are online. You can also check the
                details.
              </p>
              <Button
                color='inherit'
                component={RouterLink}
                to='/sync'
                variant='outlined'
              >
                Find out more
              </Button>
            </Alert>
          </Grid>
        )}

        {patches.rollback.length > 0 && (
          <Grid item xs={12}>
            <Alert severity='error' variant='filled'>
              <AlertTitle>There is a problem</AlertTitle>
              <p>
                There was a problem uploading data for{' '}
                <strong>
                  {patches.rollback.length} appointment
                  {patches.rollback.length > 1 && 's'}
                </strong>
                .
              </p>
              <p>
                This is probably because there was a problem with your
                internet connectivity while sending the data. All of the
                details are safely saved on your device, and you can check the
                details and retry.
              </p>
              <Button
                color='inherit'
                component={RouterLink}
                to='/sync'
                variant='outlined'
              >
                Find out more
              </Button>
            </Alert>
          </Grid>
        )}
      </Grid>

      <Box mt={3}>
        <Grid container spacing={3}>
          <Grid item sm={6}>
            <Card>
              <CardHeader
                title='Appointments'
                subheader={`You have ${
                  Object.entries(installationMap).length
                } scheduled appointments`}
              />

              <CardContent>
                Your scheduled appointments are available to view through the
                Appointments page, where you can view information about each
                one, and start an install.
              </CardContent>
              <CardActions>
                <Button
                  color='primary'
                  component={RouterLink}
                  size='small'
                  to='/mass'
                >
                  View appointments
                </Button>
              </CardActions>
            </Card>
          </Grid>

          <Grid item sm={6}>
            <Card>
              <CardHeader
                title='Data updates'
                subheader={<OfflineOutboxStatus />}
              />
              <CardContent>
                When you are working offline, any information you update in
                the app will be saved and automatically synced the next time
                the app is online.
              </CardContent>
              <CardActions>
                <Button
                  color='primary'
                  component={RouterLink}
                  size='small'
                  to='/sync'
                >
                  View sync details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
}

export default DashboardPage