import {
  Avatar,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText
} from '@mui/material'
import { ArrowForward, Assignment } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  avatar: {
    backgroundColor: theme.palette.info.dark
  }
}))

/**
 * Displays a list of collections associated with the mass installation.
 */
const MassTable = ({ mass }) => {
  const dateFormat = dateString => {
    return new Date(dateString).toDateString()
  }

  const getDateRange = item => {
    if (item?.engineering) return ''
    const start = dateFormat(item?.startsAt)
    const end = dateFormat(item?.endsAt)
    return `${start} — ${end}`
  }

  const classes = useStyles()

  const secondaryText = collection => {
    const { installations } = collection
    const plural = installations.length > 1 ? 's' : ''
    const count = `${installations.length} appointment${plural}`

    if (collection?.engineering) return count

    let dates = ''
    if (collection.startsAt && collection.endsAt) {
      dates = `/ ${getDateRange(collection)}`
    } else if (collection.startsAt) {
      dates = `/ ${collection.startsAt}`
    }

    return `${count} ${dates}`
  }

  return (
    <Card className={classes.root}>
      <CardHeader subheader={getDateRange(mass)} title={mass?.title} />

      <Divider />

      <List disablePadding>
        {mass?.collections.map(
          collection =>
            collection.id !== 0 && (
              <ListItem disablePadding key={collection.id}>
                <ListItemButton
                  divider
                  component={RouterLink}
                  to={`/mass/${mass.id}/collection/${collection.id}`}
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <Assignment />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={collection.title}
                    secondary={secondaryText(collection)}
                  />

                  <ListItemSecondaryAction>
                    <IconButton edge='end' size="large">
                      <ArrowForward />
                    </IconButton>
                  </ListItemSecondaryAction>
              
                </ListItemButton>
              </ListItem>
            )
        )}
      </List>
    </Card>
  );
}

export default MassTable
