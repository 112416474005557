import { Chip } from '@mui/material'
import { Cloud, CloudOff } from '@mui/icons-material'
import React from 'react'
import { useOnline } from '../../../providers/OnlineProvider'

/**
 * Displays the online status of the device. A boolean property `onlne` can be
 * used, or if wrapped with an `<OnlineProvider />`, will default to the context
 * derived from the provider.
 */
const OnlineStatus = props => {
  const online = useOnline() || props.online

  return (
    <Chip
      color={online ? 'primary' : 'default'}
      label={online ? 'Online' : 'Offline'}
      icon={online ? <Cloud title='Online' /> : <CloudOff title='Offline' />}
    />
  )
}

export default OnlineStatus
