import { Button } from '@mui/material'
import React from 'react'
import ReactDOM from 'react-dom'
import rg4js from 'raygun4js'
import App from './App'
import { Notification, NotificationManager } from './app/managers'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

/**
 * The main entry point for the application, which wraps the main `<App />` with
 * the data store using `<Provider />`. By adding the store at this top-level,
 * the store is included in the context of all lower-level components using
 * functional hooks.
 *
 * Also included at the moment is an instance of a Mirage `server` instance,
 * which makes mocked API calls available throughout the application. This will
 * eventually be made optional, once a physical backend exists.
 */

/**
 * Raygun authentication signals. Note that user events get sent in <App/> and
 * other events can be triggered throughout the application.
 */
const raygun = process.env.REACT_APP_RAYGUN_ENABLED === 'true'

if (raygun) {
  rg4js('apiKey', 'iNfyQFHgFexdldVE5NwxFw')
  rg4js('enableCrashReporting', true)
  rg4js('setVersion', process.env.REACT_APP_VERSION)
}

/**
 * Render the SPA.
 */
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

/**
 * If the application visibility changes, then force the application to check
 * for a new service worker. This is to help ensure that users are provided with
 * the very latest version of the application, even if they don't close the
 * app very often.
 */
document.onvisibilitychange = () => {
  document.visibilityState === 'visible' && forceWorkerUpdate()
}

/**
 * Force the app to check for an updated service worker.
 */
const forceWorkerUpdate = async () => {
  if (navigator.serviceWorker) {
    const registration = await navigator.serviceWorker.ready
    /** 
     * We wrap in a try/catch, as we are expecting a promise from `update()`,
     * and if it gets rejected, the resulting error will be handled gracefully.
     *
     * Also: note that if this fails, it is non-fatal; it usually just means
     * that the update request received a `4xx` response, but will be retried
     * on future extectutions.
     */
    try {
      await registration.update()
      console.log('Checking for a new version of the app')
    } catch (error) {
      console.error(error)
      raygun && rg4js('send', { error })
    }
  }
}

/**
 * Registers the app to work offline and to load faster. Uses callbacks here to
 * provide user feedback about application upgrades.
 * Learn more about service workers: https://cra.link/PWA
 */
const notificationConfig = {
  onSuccess: () => {
    NotificationManager.addNotification(
      new Notification({
        detail: 'The app has been upgraded successfully',
        severity: 'success'
      })
    )
  },
  onUpdate: registration => {
    const waitingSW = registration.waiting

    if (waitingSW) {
      const handleClick = () => {
        waitingSW.postMessage({ type: 'SKIP_WAITING' })
        window.location.reload()
      }

      NotificationManager.addNotification(
        new Notification({
          action: (
            <Button color='inherit' onClick={handleClick} size='small'>
              Update
            </Button>
          ),
          detail: 'A new version of the app is ready'
        })
      )
    }
  }
}

serviceWorkerRegistration.register(notificationConfig)
