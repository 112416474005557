/**
 * Resolve nested key paths within objects using a string with dot notation.
 * This is useful for referencing deeply nested keys.
 * @param {string} path
 * @param {*} obj
 */
export const resolvePath = (path, obj) => {
  path.split('.').reduce((prev, curr) => prev && prev[curr], obj)
}

/**
 * Resolve nested key paths within objects using a string with dot notation, and
 * sets the value. This is useful for referencing deeply nested keys.
 */
export const setPath = (object, path, value) =>
  path
    .split('.')
    .reduce(
      (prev, curr, i) =>
        (prev[curr] =
          path.split('.').length === ++i ? value : prev[curr] || {}),
      object
    )

/**
 * Safely stringify an object.
 */
export const stringify = obj =>
  typeof obj === 'string' ? obj : JSON.stringify(obj)
