/**
 * Change a property of the installation object. Property names can be nested,
 * using dot notation.
 *
 * @param {*} id The ID of the installation
 * @param {*} name The property name to change
 * @param {*} value The value to set the property to
 */
export const addChange = (name, value, id) => ({
  type: 'installation/change',
  payload: { id, name, value }
})
