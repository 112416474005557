import { Alert, AlertTitle, Button, Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { MassTable } from '../../organisms'
import { useOnline } from 'providers/OnlineProvider'
import { useHasExpired } from 'providers/auth'

/**
 * Shows a summary of appointments, either loaded from local storage when
 * offline, or updated from a remote API.
 */
const MassListPage = (props) => {
  /* An array of masses, rather than the keyed properties. */
  const masses = Object.entries(useSelector(state => state.masses)).map(([idx, m]) => m)

  /* We only want to allow checking of updates if we're online and our login hasn't expired */
  const online = useOnline()
  const hasExpired = useHasExpired()

  return (
    <Container sx={{ mt: 3, mb: 3 }}>
      {!masses.length && (
        <Alert severity='info' variant='filled'>
          <AlertTitle>There are currently no installs.</AlertTitle>
          <p>
            You can check for updates, so see whether there is any new install
            data to download.
          </p>
          <Button
            color='inherit'
            component={RouterLink}
            to='/fetch'
            variant='outlined'
            disabled={!online || hasExpired}
          >
            Check for updates
          </Button>
        </Alert>
      )}
      {masses.map(mass => (
        <MassTable key={mass.id} mass={mass} />
      ))}
    </Container>
  )
}

export default MassListPage
