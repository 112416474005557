import { Chip, Grid, IconButton, Paper } from '@mui/material'
import { Close } from '@mui/icons-material'
import { deviceMap, getDevice } from 'constants/devices'
import React from 'react'
import ContactHubClassicForm from './ContactHubClassicForm'
import ContactHubTouchForm from './ContactHubTouchForm'
import PhoneForm from './PhoneForm'
import SensorForm from './SensorForm'

/**
 * Depending on the type of the device, a specific form is presented.
 */
const ContextForm = props => {
  const { device } = props
  switch (device?.type) {
    case deviceMap.CONTACTHUB_TOUCH.type:
      return <ContactHubTouchForm {...props} />
    case deviceMap.CONTACTHUB_CLASSIC.type:
      return <ContactHubClassicForm {...props} />
    case deviceMap.ENVOSENSE_SENSOR.type:
      return <SensorForm {...props} />
    case deviceMap.OKEACHDAY_PHONE.type:
      return <PhoneForm {...props} />
    default:
      return null
  }
}

/**
 * A ubiquitous form container for displaying forms for specific device types.
 */
const DeviceForm = ({
  device,
  index,
  installation,
  onChange,
  onRemove,
  onSelected,
  selected,
  envosenseUniqueHelper
}) => {
  const handleChange = event => {
    onChange?.({
      index: index,
      prop: event.target.name,
      value: event.target.value
    })
  }

  const handleSelected = event => {
    onSelected?.(index)
  }

  const standardStyle = {
    marginBottom: 2,
    padding: 2
  }

  const selectedStyle = {
    ...standardStyle,
    borderColor: 'black',
    boxShadow: '0 0 0 1px black'
  }

  return (
    <Paper
      onClick={handleSelected}
      sx={selected ? selectedStyle : standardStyle}
      variant='outlined'
    >
      <Grid alignItems='center' container spacing={2}>
        <Grid item xs='auto'>
          <Chip
            color={device?.id ? 'success' : 'info'}
            label={device?.id ? 'Current' : 'New'}
          />
        </Grid>

        <Grid item alignSelf='center' xs>
          <strong>{getDevice(device?.type)?.title}</strong>
        </Grid>

        <Grid item textAlign='right' xs>
          <IconButton
            color='secondary'
            onClick={e => onRemove?.(e, index)}
            size='large'
          >
            <Close />
          </IconButton>
        </Grid>

        <ContextForm device={device} index={index} onChange={handleChange} envosenseUniqueHelper={envosenseUniqueHelper}/>
      </Grid>
    </Paper>
  )
}

export default DeviceForm
