import { createSelector } from '@reduxjs/toolkit'

/**
 * These selectors provide an easy way to compute derived data. They are
 * composable, and are only recomputed when one of their arguments changes.
 */

const visitSelector = state => state.visits || {}

export const humanizedVisitsSelector = createSelector(visitSelector, visits => {
  const count = Object.keys(visits).length + 1
  const ordinals = ['th', 'st', 'nd', 'rd']
  const ordinal = ordinals[(count - 20) % 10] || ordinals[count] || ordinals[0]
  return `${count}${ordinal}`
})
