import React, { useEffect } from 'react'
import { NotificationManager } from '../../../app/managers'
import SimpleSnackbar from '../../molecules/SimpleSnackbar'

/**
 * A container for dynamically generating notifications when they are dispatched
 * by a notification manager.  Works as a first-in-first-out queue.
 */
const NotificationContainer = () => {
  const [notifications, setNotifications] = React.useState([])
  const [current, setCurrent] = React.useState(null)
  const [open, setOpen] = React.useState(false)

  const onExited = () => {
    setCurrent(null)
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return
    setOpen(false)
  }

  useEffect(() => {
    const handleAddNotification = (e) => {
      const newNotification = e.detail
      setNotifications(currNotifications => [...currNotifications, newNotification])
    }

    const handleRemoveNotification = (e) => {
      const id = e.detail
      setNotifications(currNotifications => currNotifications.filter(n => n.id !== id))
      setCurrent(current => {
        if (current?.id === id) {
          setOpen(false)
          return null
        }
        return current
      })
    }

    NotificationManager.addEventListener('onadd', handleAddNotification)
    NotificationManager.addEventListener('onremove', handleRemoveNotification)

    return () => {
      NotificationManager.removeEventListener('onadd', handleAddNotification)
      NotificationManager.removeEventListener('onremove', handleRemoveNotification)
    }
  }, [])

  /**
   * Takes the first notification from the queue and sets it as the current
   * if there is already a notification being shown, hide it.
   * This ensures proper animation and queueing of notifications.
   */
  useEffect(() => {
    if (notifications.length && !current) {
      const { action, detail, id, severity } = notifications[0]
      setCurrent({ action, detail, id, severity })
      setNotifications(n => n.slice(1))
      setOpen(true)
    } else if (notifications.length && current && open) {
      setOpen(false)
    }
  }, [notifications, current, open])

  const { action, detail, id, severity } = current || {}

  return (
    <>
    <SimpleSnackbar
      open={open}
      action={action}
      key={id}
      message={detail}
      handleClose={handleClose}
      onExited={onExited}
      severity={severity}
    />
    </>
  )
}

export default NotificationContainer
