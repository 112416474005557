import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField
} from '@mui/material'
import {
  PhoneTextField,
  RequiredTextField,
  TitleSelect
} from 'components/inputs'
import EmailTextField from 'components/inputs/EmailTextField'
import React from 'react'

/**
 * A reusable form for customer's nominated contacts.
 */
const SecondaryContactForm = ({
  contact = {},
  index = 0,
  onChange = () => {}
}) => {
  const commonProps = {
    fullWidth: true,
    variant: 'outlined'
  }

  /**
   * Clones, then patches the contact object, and passes it to the parent for
   * state management
   */
  const handleChange = event => {
    const clone = { ...contact }
    clone[event.target.name] = event.target.value
    onChange(clone, index)
  }

  return (
    <form>
      <h4>Contact {index + 1}</h4>
      <Grid container spacing={2}>
        <Grid item md={4}>
          <TitleSelect
            {...commonProps}
            id='title'
            name='title'
            onChange={handleChange}
            required
            value={contact.title}
          />
        </Grid>

        <Grid item md={4}>
          <RequiredTextField
            {...commonProps}
            label='First name'
            id='firstName'
            name='firstName'
            onChange={handleChange}
            required
            value={contact.firstName}
          />
        </Grid>

        <Grid item md={4}>
          <RequiredTextField
            {...commonProps}
            id='surname'
            label='Surname'
            name='surname'
            onChange={handleChange}
            required
            value={contact.surname}
          />
        </Grid>

        <Grid item md={4}>
          <TextField
            {...commonProps}
            id='greeting'
            label='Greeting'
            name='greeting'
            onChange={handleChange}
            value={contact.greeting}
          />
        </Grid>

        <Grid item md={8}>
          <TextField
            {...commonProps}
            id='notes'
            label='Relationship to customer'
            name='notes'
            onChange={handleChange}
            value={contact?.notes}
          />
        </Grid>

        <Grid item md={6}>
          <PhoneTextField
            {...commonProps}
            id='phone1'
            label='Main phone number'
            name='phone1'
            onChange={handleChange}
            required
            value={contact.phone1}
          />
        </Grid>

        <Grid item md={6}>
          <PhoneTextField
            {...commonProps}
            id='phone2'
            label='Other phone number'
            name='phone2'
            onChange={handleChange}
            value={contact.phone2}
          />
        </Grid>

        <Grid item md={6}>
          <EmailTextField
            {...commonProps}
            id='email'
            label='Email address'
            name='email'
            onChange={handleChange}
            value={contact.email}
          />
        </Grid>

        <Grid item md={3}>
          <FormControlLabel
            color='primary'
            control={<Checkbox name='checkedA' />}
            id='local'
            label='Local'
            name='local'
            onChange={handleChange}
          />
        </Grid>

        <Grid item md={3}>
          <FormControlLabel
            color='primary'
            control={<Checkbox name='checkedA' />}
            id='keyHolder'
            label='Keyholder'
            name='keyHolder'
            onChange={handleChange}
          />
        </Grid>

        <Grid item md={3}>
          <FormControlLabel
            color='primary'
            control={<Checkbox name='checkedA' />}
            id='seesOften'
            label='Sees often'
            name='seesOften'
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Divider />
    </form>
  )
}

export default SecondaryContactForm
