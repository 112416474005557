import AuthWatcher from 'components/auth'
import ErrorBoundary from 'components/errors'
import NavBar from 'components/navigation/NavBar'
import {
  CollectionPage,
  DashboardPage,
  FetchPage,
  InstallPage,
  MassListPage,
  MassPage,
  SyncPage
} from 'components/pages'
import DevicePage from 'components/pages/DevicePage'
import EngineeringPage from 'components/pages/EngineeringPage'
import React from 'react'
import { Route, Switch } from 'react-router-dom'

/**
 * A higher order component which provides an instance of the authenticated
 * application. The `<Switch />` defined here provides the top-level routes for
 * navigating the app.
 *
 * Routes should be defined in order of granular specificity, as the path
 * matching is greedy by default.
 */

const AuthenticatedApp = () => {
  return (
    <ErrorBoundary>
      <AuthWatcher />
      <NavBar />

      <Switch>
        <Route
          path='/mass/:massId/collection/:collectionId/device/:installationId'
          component={DevicePage}
        />
        <Route
          path='/mass/:massId/collection/:collectionId/engineering/:installationId'
          component={EngineeringPage}
        />
        <Route
          path='/mass/:massId/collection/:collectionId/installation/:installationId/step/:step'
          component={InstallPage}
        />
        <Route
          path='/mass/:massId/collection/:collectionId'
          component={CollectionPage}
        />
        <Route path='/mass/:massId' component={MassPage} />
        <Route path='/mass' component={MassListPage} />
        <Route path='/sync' component={SyncPage} />
        <Route path='/dashboard' component={DashboardPage} />
        <Route path='/' component={FetchPage} />
      </Switch>
    </ErrorBoundary>
  )
}

export default AuthenticatedApp
