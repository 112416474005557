import { TextField } from '@mui/material'
import { InputAdornment, Link, MenuItem } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'
import React, { useRef } from 'react'

/**
 * A user-friendly input component for entering a title.
 */
const TitleSelect = ({ onChange = () => {}, value = 'Mr', ...rest }) => {
  const textInput = useRef(null)
  const staticTitles = ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr', 'Prof', 'Rev']
  const otherTitle = 'Other'
  const titles = [...staticTitles, otherTitle]

  const [title, setTitle] = React.useState(value)
  const isSelect = staticTitles.some(v => v === title)
  const [select, setSelect] = React.useState(isSelect)

  React.useEffect(() => {
    setSelect(isSelect)
  }, [value, title, isSelect])
 
  React.useEffect(() => {
    !select && textInput.current.focus()
  }, [select])

  const handleChange = (event = { target: { value: '' } }) => {
    const { value } = event?.target
    if (value === otherTitle) {
      setTitle('') 
      return
    }
    setTitle(value)
    onChange(event)
  }

  const handleReset = event => {
    setTitle(titles[0])
  }

  const adornment = !select && (
    <Link component='button' onClick={handleReset}>
      <ArrowBack />
    </Link>
  )

  const menuItems = titles.map(title => (
    <MenuItem key={title} value={title}>
      {title}
    </MenuItem>
  ))

  const inputProps = {
    startAdornment: (
      <InputAdornment position='start'>{adornment}</InputAdornment>
    )
  }

  return (
    <>
      <TextField
        {...rest}
        fullWidth
        helperText='E.g. Mr, Mrs, Miss'
        inputRef={textInput}
        InputProps={inputProps}
        label='Title'
        onChange={handleChange}
        select={select}
        value={title}
      >
        {menuItems}
      </TextField>
    </>
  )
}

export default TitleSelect
