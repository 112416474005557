import { StepButton } from '@mui/material'

/**
 * Stepper button, which is clickable if the step preceeds the currently active
 * step.
 */
const CustomStepperButton = ({ activeStep, index, label, onActiveStep }) => {
  return (
    <StepButton
      disabled={index > activeStep}
      color='inherit'
      onClick={() => onActiveStep(index)}
    >
      {label}
    </StepButton>
  )
}

export default CustomStepperButton
