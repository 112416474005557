import { createReducer } from '@reduxjs/toolkit'
import initialState from './initialState'

/**
 * Reducer which handles any Redux offline persistence and hydration actions.
 */
export const persistReducer = createReducer(initialState, builder => {
  builder
    /**
     * Notes that an installation is currently busy, which means it is
     * effectively in the process of having it's state edited.
     */
    .addCase('persist/REHYDRATE', (state, action) => {
      state.hydrated = true
    })

    /**
     * Be a good citizen and at the very least, pass back the state.
     */
    .addDefaultCase((state, action) => state)
})
