import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField
} from '@mui/material'
import React from 'react'
import { postponedOutcomesMap } from '../../../constants/outcomes'

class AppointmentPostponeDialog extends React.Component {
  constructor (props) {
    super(props)
    this.state = { reason: null, notes: '' }
    this.baseState = this.state

    this.handleChange = this.handleChange.bind(this)
    this.handleExited = this.handleExited.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleChange (event) {
    this.setState({ [event.target.name]: event.target.value })
  }

  handleExited () {
    this.reset()
  }

  handleSubmit () {
    const reason = postponedOutcomesMap[this.state.reason]
    const notes = `${this.state.reason}. ${this.state?.notes}`
    const type = 1
    this.props.onSubmit({ notes: notes, reason: reason, type: type })
    this.props.onClose()
  }

  reset () {
    this.setState(this.baseState)
  }

  render () {
    return (
      <Dialog
        aria-labelledby='postpone-dialog-title'
        fullWidth
        maxWidth='md'
        onClose={this.props.onClose}
        open={this.props.open}
        TransitionProps={{
          onExited: this.handleExited
        }}
      >
        <DialogTitle id='postpone-dialog-title'>
          Postpone this visit
        </DialogTitle>

        <DialogContent>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>Reason for postponing</FormLabel>

            <RadioGroup
              name='reason'
              onChange={this.handleChange}
              value={this.state.reason}
            >
              {Object.entries(postponedOutcomesMap).map(([key, value]) => (
                <FormControlLabel
                  key={key}
                  value={key}
                  control={<Radio />}
                  label={key}
                />
              ))}
            </RadioGroup>
          </FormControl>

          <TextField
            fullWidth
            label='Comments'
            margin='normal'
            multiline
            name='notes'
            onChange={this.handleChange}
            rows={4}
            value={this.state?.notes}
            variant='outlined'
          />
        </DialogContent>

        <DialogActions>
          <Button
            color='primary'
            disabled={!this.state.reason}
            onClick={this.handleSubmit}
            variant='contained'
          >
            Confirm
          </Button>
          <Button onClick={this.props.onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default AppointmentPostponeDialog
