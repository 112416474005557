import { TextField } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TimePicker from '@mui/lab/TimePicker'
import React from 'react'

/**
 * A user-friendly input comopnent for entering a time. It uses a text field,
 * with a timepicker widget, to help with user understanding of the date format,
 * and aid validation.
 *
 * Inputs are restricted to allow 30 minute increments.
 */
const TimeInput = props => {
  const { defaultTime, maxTime, minTime, onChange } = props

  const handleChange = date => {
    const event = {
      target: {
        type: 'input',
        value: date
      }
    }
    onChange(event)
  }

  /**
   * 👇 Sets a default time when opening the picker, because having an
   * undefined/null value, causes issues with min/max validation.
   */
  const handleOpen = () => {
    handleChange(defaultTime)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        ampm={false}
        clearable
        label={props.label}
        minutesStep={30}
        minTime={minTime}
        maxTime={maxTime}
        onChange={date => handleChange(date)}
        onOpen={handleOpen}
        renderInput={props => <TextField {...props} variant='outlined' />}
        shouldDisableTime={(time, type) => type === 'minutes' && time % 30}
        showToolbar
        value={props.value || (props.required && '') || null}
      />
    </LocalizationProvider>
  )
}

export default TimeInput
