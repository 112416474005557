import { TextField } from '@mui/material'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import enLocale from 'date-fns/locale/en-GB'
import React from 'react'

/**
 * A user-friendly input comopnent for entering a date. It uses a text field,
 * with a mask applied, to help with user understanding of the date format, and
 * to aid validation.
 */
const DateInput = (props, ref) => {
  const propAsDate = new Date(props.value)

  const [selectedDate, setSelectedDate] = React.useState(
    ((propAsDate >= props.minDate) && (props.maxDate >= propAsDate)) ? props.value : null
  )

  const handleDateChange = date => {
    setSelectedDate(date)

    if (isNaN(date)) return
    const event = {
      target: {
        type: 'input',
        value: date
      }
    }
    props?.onChange(event)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={enLocale}>
      <DatePicker
        label={props.label}
        maxDate = {props.maxDate}
        minDate = {props.minDate}
        onChange={date => handleDateChange(date)}
        openTo={'year'}
        renderInput={props => <TextField
          {...props} 
          helperText={"Select a valid date"}
          variant='outlined' 
          />}
        value={selectedDate}
      />
    </LocalizationProvider>
  )
}

export default DateInput