import { Container, Divider } from '@mui/material'
import { Box } from '@mui/system'
import styles from './Banner.module.css'

/**
 * A simple banner which can be used to display messages to the user.
 *
 * @param {object} props
 * @param {string} props.position
 * @param {string} props.label
 */
const Banner = props => {
  const {
    color: colorVariant = 'background.paper',
    label,
    position = 'fixed'
  } = props

  let bgcolor = colorVariant
  let color = 'text.primary'

  switch (colorVariant) {
    case 'error':
      bgcolor = 'error.main'
      color = 'error.contrastText'
      break
    case 'info':
      bgcolor = 'info.main'
      color = 'info.contrastText'
      break
    case 'success':
      bgcolor = 'success.main'
      color = 'success.contrastText'
      break
    default:
      break
  }

  return (
    <>
      {label && (
        <Box
          className={styles.root}
          sx={{ bgcolor, color, position, width: 1 }}
        >
          <Divider />
          <Container>
            <Box sx={{ px: 2, py: 2 }}>{label}</Box>
          </Container>
        </Box>
      )}
    </>
  )
}

export default Banner
