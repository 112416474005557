import { createSelector } from '@reduxjs/toolkit'

const titleSelector = state => state?.account?.customer.title || ''
const firstNameSelector = state => state?.account?.customer.firstName || ''
const surnameSelector = state => state?.account?.customer.surname || ''

export const fullNameSelector = createSelector(
  titleSelector,
  firstNameSelector,
  surnameSelector,
  (title, firstName, surname) => [title, firstName, surname].join(' ')
)
