import { Card, CardHeader, Divider, List } from '@mui/material'
import React, { useEffect } from 'react'
import CollectionTableRow from './CollectionTableRow'
import TablePagination from '@mui/material/TablePagination';
import Stack from '@mui/material/Stack'
import { usePageDispatch, usePageNumber, usePageSize, useNumItems, useNumPages } from 'providers/PageProvider'

function PageNumberComponent({ page, numPages, count, onChange, pageSize, setPageSize }) {
  // page is 0 indexed but numPages is 1 indexed
  const tmpPage = page >= numPages ? 0 : page
  return (
    <Stack spacing={2}>
      <TablePagination 
        component="div" 
        count={count} 
        page={tmpPage} 
        onPageChange={onChange} 
        rowsPerPage={pageSize} 
        onRowsPerPageChange={setPageSize} 
        rowsPerPageOptions={[10, 25, 50, 100, { value: -1, label: 'All' }]}
      />
    </Stack>
  );
}

/**
 * Display a list of installations associated with the collection.
 */
const CollectionTable = ({ mass, collection, installations }) => {
  const [pagedInstallations, setPagedInstallations] = React.useState([])
  
  const pageNumber = usePageNumber()
  const pageSize = usePageSize()
  const numPages = useNumPages()
  const count = useNumItems()
  
  const { setPage, setPageSize } = usePageDispatch()

  const dateTimeFormat = new Intl.DateTimeFormat('en-GB', {
    dateStyle: 'medium'
  })

  useEffect(() => {
    if (!installations?.length) {
      setPagedInstallations([])
      return
    }
    
    if (pageSize === -1) {
      setPagedInstallations(installations)
      return
    }

    const start = pageNumber * pageSize
    const end = (pageNumber * pageSize) + pageSize
    setPagedInstallations(installations.slice(start, end))
  }, [pageNumber, installations, pageSize])

  const getSubheader = () => {
    const title = mass?.title

    if (collection?.engineering) return title

    let dates = ''
    if (collection?.startsAt && collection?.endsAt) {
      dates = `/ ${dateTimeFormat.format(
        new Date(collection.startsAt)
      )} — ${dateTimeFormat.format(new Date(collection.endsAt))}`
    } else if (collection?.startsAt) {
      dates = `/ ${collection.startsAt}`
    }

    return `
      ${mass?.title} /
      ${collection?.installations.length} appointments
      ${dates}
    `
  }

  const handlePageSizeChange = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Card>
      <CardHeader subheader={getSubheader()} title={collection?.title} />
      <Divider />
      <List disablePadding role='list'>
        {pagedInstallations.map(installation => (
          <CollectionTableRow
            key={installation.id}
            collection={collection}
            installation={installation}
            mass={mass}
          />
        ))}
      </List>
      <PageNumberComponent page={pageNumber} onChange={(_, value) => setPage(value)} pageSize={pageSize} setPageSize={handlePageSizeChange} numPages={numPages} count={count}  />
    </Card>
  )
}

export default CollectionTable
