import { Button, Grid, Step, StepContent } from '@mui/material'
import StepperButton from 'components/steppers/StepperButton'
import React from 'react'
import { SecondaryContactForm } from '../../../organisms'

/**
 * If setting up an initial contact, it won't be persisted, so have to hack
 * around making sure a default value is set for 'title'.
 */
const newContact = { title: 'Mr' }

/**
 * Step to collect information about the occupant(s) secondary contacts.
 */
const NominatedContactsStep = ({
  activeStep,
  index,
  installation,
  onActiveStep,
  onChange,
  onValid,
  ...rest
}) => {
  const contacts = React.useMemo(
    () =>
      installation?.account?.contacts
        ? installation?.account.contacts
        : [{ ...newContact }],
    [installation?.account?.contacts]
  )

  /**
   * Validate this step whenever any changes are made.
   */
  React.useEffect(() => {
    const required = contacts.flatMap(contact => [
      contact?.firstName,
      contact?.phone1,
      contact?.surname
    ])

    if (activeStep === index) {
      const valid = !required.some(e => e === undefined || e === '')
      onValid(valid)
    }
  }, [activeStep, index, contacts, onValid])

  /**
   * Cloning the whole array, patching it, then passing it on to the partent
   * container to store in state.
   */
  const handleChange = (contact, idx) => {
    const clone = [...contacts]
    clone[idx] = contact
    const event = getEvent('input', clone)

    onChange('account.contacts', event)
  }

  const getEvent = (type, value) => {
    return {
      target: {
        type: type,
        value: value
      }
    }
  }

  const handleAddContact = () => {
    const clone = [...contacts]
    clone.push({ ...newContact })
    const event = getEvent('input', clone)
    onChange('account.contacts', event)
  }

  return (
    <Step index={index} {...rest}>
      <StepperButton
        activeStep={activeStep}
        index={index}
        label='Nominated contacts'
        onActiveStep={onActiveStep}
      />
      <StepContent>
        <Grid container spacing={2}>
          {contacts.map((contact, idx) => (
            <Grid item key={idx}>
              <SecondaryContactForm
                contact={contact}
                index={idx}
                onChange={handleChange}
              />
            </Grid>
          ))}

          <Grid item>
            <Button
              color='primary'
              onClick={handleAddContact}
              variant='contained'
            >
              Add a new contact
            </Button>
          </Grid>
        </Grid>
      </StepContent>
    </Step>
  )
}

export default NominatedContactsStep
