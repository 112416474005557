import { Grid } from '@mui/material'
import {
  DateInput,
  GenderSelect,
  RequiredTextField,
  TitleSelect
} from 'components/inputs'
import React from 'react'
import { minimumDobDate, maximumDobDate } from 'constants/customer'

/**
 * Form to collect information about the occupant(s) of a property.
 */

const PrimaryContactForm = ({
  contact = {},
  installation,
  isSecondary,
  object,
  onChange
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          {!isSecondary && (
            <TitleSelect
              id='title'
              onChange={val => onChange('account.customer.title', val)}
              required
              value={installation?.account?.customer.title}
              variant='outlined'
            />
          )}
          {isSecondary && (
            <TitleSelect
              id='title2'
              onChange={val => onChange('account.customer.title2', val)}
              required
              value={installation?.account?.customer.title2}
              variant='outlined'
            />
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          {!isSecondary && (
            <RequiredTextField
              id='firstName'
              label='First name'
              onChange={val => onChange('account.customer.firstName', val)}
              value={installation?.account?.customer.firstName}
            />
          )}

          {isSecondary && (
            <RequiredTextField
              id='firstName2'
              label='First name'
              onChange={val => onChange('account.customer.firstName2', val)}
              value={installation?.account?.customer.firstName2}
            />
          )}
        </Grid>
        <Grid item sm={4} xs={12}>
          {!isSecondary && (
            <RequiredTextField
              id='surname'
              label='Surname'
              onChange={val => onChange('account.customer.surname', val)}
              value={installation?.account?.customer.surname}
            />
          )}

          {isSecondary && (
            <RequiredTextField
              id='surname2'
              label='Surname'
              onChange={val => onChange('account.customer.surname2', val)}
              value={installation?.account?.customer.surname2}
            />
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          {!isSecondary && (
            <RequiredTextField
              helperText='E.g. Fred, Mr Smith'
              id='greeting'
              label='How should we greet this person?'
              onChange={val => onChange('account.customer.greeting', val)}
              value={installation?.account?.customer.greeting}
            />
          )}

          {isSecondary && (
            <RequiredTextField
              helperText='E.g. Fred, Mr Smith'
              id='greeting2'
              label='How should we greet this person?'
              onChange={val => onChange('account.customer.greeting2', val)}
              value={installation?.account?.customer.greeting2}
            />
          )}
        </Grid>

        <Grid item sm={3} xs={12}>
          {!isSecondary && (
            <DateInput
              id='dateOfBirth'
              label='Date of birth'
              maxDate = {maximumDobDate}
              minDate = {minimumDobDate}
              onChange={val => onChange('account.customer.dateOfBirth', val)}
              value={installation?.account?.customer.dateOfBirth}
              variant='outlined'
            />
          )}
          {isSecondary && (
            <DateInput
              id='dateOfBirth2'
              label='Date of birth'
              maxDate = {maximumDobDate}
              minDate = {minimumDobDate}
              onChange={val => onChange('account.customer.dateOfBirth2', val)}
              value={installation?.account?.customer.dateOfBirth2}
              variant='outlined'
            />
          )}
        </Grid>

        <Grid item sm={3} xs={12}>
          {!isSecondary && (
            <GenderSelect
              id='gender'
              onChange={val => onChange('account.customer.gender', val)}
              value={installation?.account?.customer.gender}
              variant='outlined'
            />
          )}

          {isSecondary && (
            <GenderSelect
              id='gender2'
              onChange={val => onChange('account.customer.gender2', val)}
              value={installation?.account?.customer.gender2}
              variant='outlined'
            />
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default PrimaryContactForm
