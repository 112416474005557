import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material'
import React from 'react'

/**
 * Provides a dialog for the display of housing provider notes.
 */
const ProviderNotesDialog = ({ onClose, open, mass }) => {
  const getHtml = () => {
    if (!mass?.notes) {
      return <p>There are no notes provided for this installation.</p>
    }
    const paras = (mass?.notes && mass.notes.split(/\n/)) || []
    return paras.map(p => <p key={p}>{p}</p>)
  }

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      fullWidth
      onClose={onClose}
      open={open}
    >
      <DialogTitle>{mass.title}</DialogTitle>
      <DialogContent>{getHtml()}</DialogContent>
      <DialogActions>
        <Button
          size='large'
          autoFocus
          color='primary'
          onClick={onClose}
          variant='contained'
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ProviderNotesDialog
