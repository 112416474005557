import {
  FormControlLabel,
  Grid,
  Step,
  StepContent,
  Switch
} from '@mui/material'
import StepperButton from 'components/steppers/StepperButton'
import React from 'react'
import { PrimaryContactForm } from '../../../organisms'

/**
 * Step to collect information about the occupant(s) of a property.
 */
const CustomerStep = ({
  activeStep,
  index,
  installation,
  onActiveStep,
  onChange,
  onValid,
  ...rest
}) => {
  React.useEffect(() => {
    const required = [
      installation?.account?.customer?.firstName,
      installation?.account?.customer?.surname,
      installation?.account?.customer?.title,
      installation?.account?.customer?.greeting
    ]

    const secondaryRequired = [
      installation?.account?.customer?.firstName2,
      installation?.account?.customer?.surname2,
      installation?.account?.customer?.title2,
      installation?.account?.customer?.greeting2
    ]

    if (installation?.account?.hasSecondOccupant) required.push(...secondaryRequired)

    if (activeStep === index) {
      const valid = !required.some(e => e === '')
      onValid(valid)
    }
  }, [activeStep, index, installation, onValid])

  /**
   * If a customer has 'Unknown' as a name, then it's a safe bet that it should
   * just be cleared.
   */
  const possibleUnknowns = ['firstName', 'firstName2', 'surname', 'surname2']

  possibleUnknowns.forEach(prop => {
    if (installation?.account?.customer[prop] === 'Unknown') {
      onChange(`account.customer.${prop}`, '')
    }
  })

  return (
    <Step index={index} {...rest}>
      <StepperButton
        activeStep={activeStep}
        index={index}
        label='About the occupant(s)'
        onActiveStep={onActiveStep}
      />
      <StepContent>
        <Grid container spacing={1}>
          <Grid item>
            <PrimaryContactForm
              installation={installation}
              object='customer'
              onChange={onChange}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Switch name='checked' color='primary' />}
              label='Is there a second occupant?'
              onChange={val => onChange('account.hasSecondOccupant', val)}
              checked={installation?.account?.hasSecondOccupant}
            />
          </Grid>
          <Grid item>
            {installation?.account?.hasSecondOccupant && (
              <PrimaryContactForm
                installation={installation}
                isSecondary
                object='secondOccupant'
                onChange={onChange}
              />
            )}
          </Grid>
        </Grid>
      </StepContent>
    </Step>
  )
}

export default CustomerStep
