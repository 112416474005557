import { createReducer } from '@reduxjs/toolkit'
import initialState from './initialState'

/**
 * Reducer which handles patch queues (fetch, commit, rollback).
 */
export const patchReducer = createReducer(initialState, builder => {
  builder
    /**
     * Sometimes, particularly during dev/test effects can become orphaned. This
     * is usually because the UUID property has become detached. So this is a
     * brute force way to clear all effects.
     */
    .addCase('effects/clear', state => {
      state.patches.effect = []
    })

    /**
     * Be a good citizen and at the very least, pass back the state.
     */
    .addDefaultCase((state, action) => state)
})
