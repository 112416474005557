import { useOnline } from 'providers/OnlineProvider'
import React, { useCallback, useState, useMemo, useEffect, useRef } from 'react'

const GeocodeContext = React.createContext()
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const CHECK_INTERVAL = 50

/**
 * Shorthand for `window.localStorage`.
 */
const ls = window.localStorage

function GeocodeProvider({ children }) {
  const [addresses, setAddresses] = useState([])
  const geocodeMap = useRef({})
  const geoQueue = useRef([])
  const interval = useRef(null)
  const [allGeocodes, setAllGeocodes] = useState(false)
  const online = useOnline()

  /**
   * Stores the geocodeMap in local storage.
   */
  const setInStore = useCallback(() => {
    ls.setItem('geocodeMap', JSON.stringify(geocodeMap.current))
  }, [])

  /**
   * Gets the geocodeMap from local storage.
   */
  const getFromStore = useCallback(() => {
    try {
      const geocodeMapStore = JSON.parse(ls.getItem('geocodeMap'))
      if (geocodeMapStore) geocodeMap.current = geocodeMapStore
    } catch (err) {
      console.error('Failed to get geocodeMap from local storage:', err)
    }    
  }, [])

  /**
   * Loads the geocodeMap from local storage on mount.
   */
  useEffect(() => {
    getFromStore()
  }, [getFromStore])

  /**
   * Checks if all addresses have a corresponding geocode in the geocodeMap.
   */
  const checkIfAllGeocodes = useCallback(() => {
    setAllGeocodes(addresses.every(address => geocodeMap.current[address] != null))
  }, [addresses]) 

  /**
   * Each time address list changes, update to check if all geocodes are present.
   */
  useEffect(() => {
    checkIfAllGeocodes()
  }, [checkIfAllGeocodes])

  /** 
   * Gets geocode information from the google maps API
   * and stores it in the geocodeMap.
   */
  const fetchGeocode = useCallback(async address => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_KEY}`
    const method = 'GET'
    const headers = { 'Content-Type': 'text/plain' }
    const response = await fetch(url, { method, headers })
    const data = await response.json()
    geocodeMap.current[address] = { location: data.results[0]?.geometry.location }
  }, [])

  /**
   * Dequeues addresses every 50ms from the queue, 
   * and fetches geocodes for them.
   */
  useEffect(() => {
    clearInterval(interval.current)

    interval.current = setInterval(async () => {
      if (!geoQueue.current.length) return
      if (!online) return
      
      const address = geoQueue.current[0]
      geoQueue.current = geoQueue.current.slice(1)  

      try {
        await fetchGeocode(address)
        setInStore()

        // We've got a new geocode, so check if we've got all
        checkIfAllGeocodes()
      } catch (err) {
        console.error('Failed to fetch geocode for address:', address)
        // Requeue the address if the geocode fetch fails
        geoQueue.current = [...geoQueue.current, address]
      }
    }, CHECK_INTERVAL)

    return () => clearInterval(interval.current)
  }, [checkIfAllGeocodes, fetchGeocode, online, setInStore])

  /**
   * Get an array of all the addresses found in installations, as nicely
   * formatted strings.
   */
  const setInstallations = useCallback((installations) => {
    const tmpAddresses = installations
      .map(installation => {
        return (
          installation?.account?.customer &&
          [
            installation.account.customer.address1,
            installation.account.customer.address2,
            installation.account.customer.town,
            installation.account.customer.county,
            installation.account.customer.postcode
          ]
            .filter(part => part)
            .join(', ')
        )
      })
      .filter(address => address)
    setAddresses(tmpAddresses)

    const toFetch = tmpAddresses.filter(address => !geocodeMap.current[address])
    geocodeMap.current = { ...geocodeMap.current, ...toFetch.reduce((acc, address) => ({ ...acc, [address]: null }), {}) }
    geoQueue.current = [...toFetch, ...geoQueue.current]
  }, [])

  /**
   * Checks whether every address has a corresponding key in the geocode map,
   * which helps determine whether an attempt has been made to get all geocodes.
   */
  const hasAllGeocodes =( useCallback(() => {
    return allGeocodes
  }, [allGeocodes]))

  /**
   * Get some standardised location objects, by iterating addresses, and mixing
   * in geocode data.
   */
  const getLocationObjects = useCallback(() => {
    // Get all entries matching our address list
    const addressFromMap = addresses.map(address => ({
      title: address,
      ...geocodeMap.current[address]
    }))
    // Return any with non-null location values
    return addressFromMap.filter(address => address?.location != null)
  }, [addresses])

  const value = useMemo(() => ({
    hasAllGeocodes,
    getLocationObjects,
    setInstallations
  }), [getLocationObjects, hasAllGeocodes, setInstallations])

  return (
    <GeocodeContext.Provider value={value}>{ children }</GeocodeContext.Provider>
  )
}

function useGeocodeDispatch() {
  const context = React.useContext(GeocodeContext)
  if (context === undefined) {
    throw new Error('useGeocodeDispatch must be used within a GeocodeContext')
  }
  return context
}

export { useGeocodeDispatch, GeocodeProvider }