import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  MenuItem,
  TextField
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  Call,
  NavigateBefore,
  NavigateNext,
  PinDrop,
  Wc
} from '@mui/icons-material'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import AppointmentCardHeader from '../../molecules/AppointmentCardHeader'

const useStyles = makeStyles(theme => ({}))

/**
 * Detail view for an appointment. Provides an overview of pertinent information
 * relating to the appointment.
 */
const AppointmentOverviewCard = props => {
  const classes = useStyles()

  return (
    <>
      {props.appointment && (
        <>
          <Card>
            <AppointmentCardHeader {...props} />
            <CardContent className={classes.content}>
              <Grid container spacing={3}>
                <Grid item sm={4} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Call />
                        </InputAdornment>
                      )
                    }}
                    label='Phone'
                    value={props.appointment.customer.phone1}
                    variant='filled'
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Call />
                        </InputAdornment>
                      )
                    }}
                    label='Mobile'
                    value={props.appointment.customer.mobile}
                    variant='filled'
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Call />
                        </InputAdornment>
                      )
                    }}
                    label='Other phone'
                    value={props.appointment.customer.phone2}
                    variant='filled'
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label='Property ID'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <PinDrop />
                        </InputAdornment>
                      )
                    }}
                    value={props.appointment.property.id}
                    variant='filled'
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    id='gender'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <Wc />
                        </InputAdornment>
                      )
                    }}
                    label='Gender'
                    value={props.appointment.customer.gender}
                    variant='filled'
                  >
                    <MenuItem key='male' value='male'>
                      Male
                    </MenuItem>
                    <MenuItem key='female' value='female'>
                      Female
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label='Notes'
                    multiline
                    variant='filled'
                    value={props.appointment?.customer?.notes}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled
                    fullWidth
                    label='Medical notes'
                    multiline
                    variant='filled'
                    value={props.appointment.customer.medicalNotes}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          <Box mt={2}>
            <Grid
              alignItems='center'
              justifyContent='space-between'
              container
              spacing={2}
            >
              <Grid container item sm={6} spacing={2}>
                <Grid item>
                  <Button
                    component={RouterLink}
                    to='/appointments'
                    size='large'
                    startIcon={<NavigateBefore />}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.completedButton}
                    color='primary'
                    component={RouterLink}
                    endIcon={<NavigateNext />}
                    size='large'
                    to={['/appointment', props.appointment.id, 'install'].join(
                      '/'
                    )}
                    variant='contained'
                  >
                    Start the install
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  )
}

export default AppointmentOverviewCard
