import { TextField } from '@mui/material'
import React from 'react'
import RequiredTextField from '../RequiredTextField'
import {sanitisePhoneNumber} from './PhoneFieldHelper'

/**
 * Text field which restricts value to a UK phone format.
 *
 * @param {Object} props
 * @param {String} props.value
 */
const PhoneTextField = props => {
  const { value = '' } = props
  const removeRX = /\D/g

  let { invalid, sanitizedValue, cleanValue } = sanitisePhoneNumber(value);

  const handleChange = e => {
    e.target.value = e.target.value.replace(removeRX, '')
    props.onChange(e)
  }

  return (
    <>
      <TextField
        sx={{ display: 'none' }}
        onChange={props.onChange}
        value={cleanValue}
      />
      {(props.required && (
        <RequiredTextField
          error={invalid}
          helperText={invalid && 'Must be a valid phone number'}
          {...props}
          onChange={handleChange}
          value={sanitizedValue}
        />
      )) || (
        <TextField
          error={invalid}
          fullWidth
          helperText={invalid && 'Must be a valid phone number'}
          variant='outlined'
          {...props}
          onChange={handleChange}
          value={sanitizedValue}
        />
      )}
    </>
  )
}

export default PhoneTextField
