import { NewReleases } from '@mui/icons-material'
import { Chip } from '@mui/material'
import {
  appointmentTypes,
  getAppointmentSlotString
} from 'constants/appointments'

/**
 * Used to help with time formatting.
 */
const timeFormat = new Intl.DateTimeFormat('en-GB', {
  timeStyle: 'short'
})

/**
 * Displays a context-sensitive time for an appointment, based on the
 * appointment slot type.
 */
const AppointmentTime = ({ appointment = {} }) => {
  /**
   * Get a formatted time slot string, based on the type.
   */
  const time = () => {
    switch (appointment.appointmentType) {
      case appointmentTypes.TIMED:
        return (
          appointment?.appointmentTime &&
          timeFormat.format(new Date(appointment.appointmentTime))
        )
      case appointmentTypes.PERIOD:
        return getAppointmentSlotString(appointment.timeSlot)
      case appointmentTypes.TENTATIVE:
        return 'Tentative'
      default:
        return 'DEFAULT'
    }
  }

  /**
   * If a priority, then presents an indicator.
   */
  const adornment =
    (appointment?.priority && <NewReleases title='Priority' />) || undefined

  return <Chip color='info' icon={adornment} label={time()} size='small' />
}

export default AppointmentTime
