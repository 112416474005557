import { Container, Grid, LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { fetchMasses } from 'app/store/masses/massesSlice'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useOnline } from 'providers/OnlineProvider/OnlineProvider'
import { useAuth, useHasExpired, useIsSignedIn } from 'providers/auth'

const useStyles = makeStyles(theme => ({
  root: {},
  grid: {
    height: '100vh'
  },
  item: {
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(3)
  }
}))

const LoadingContainer = props => {
  const classes = useStyles()

  return (
    <Container className={classes.root} component='main' maxWidth='xs'>
      <Grid
        className={classes.grid}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'
      >
        <Grid item className={classes.item}>
          Loading...
        </Grid>
        <Grid item className={classes.item}>
          <LinearProgress
            variant='determinate'
            value={props.progress}
            {...props}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

const FetchPage = ({
  fetchMasses,
  history,
  massStatus,
  hydrated
}) => {
  const [progress, setProgress] = React.useState(0)
  const online = useOnline()
  const [fetching, setFetching] = React.useState(false)
  const { credential } = useAuth()
  const hasExpired = useHasExpired()
  const signedIn = useIsSignedIn()

  const fetchProxy = useCallback(() => {
    setProgress(10)
    fetchMasses(credential)
  }, [fetchMasses, credential])

  // If we've got here and not logged in or expired, then send to dashboard
  // which will handle as per normal flow.  If we're hydrated and online, then
  // we can start fetching the masses. 
  React.useEffect(() => {
    if (!signedIn || hasExpired) {
      history.push('/dashboard')
      return
    }
    if (hydrated && online) {
      setFetching(true)
      fetchProxy()
      return
    }
  }, [history, hydrated, online, signedIn, fetchProxy, hasExpired])

  React.useEffect(() => {
    if (
      fetching &&
      ['succeeded', 'failed'].some(status => status === massStatus)
    ) {
      setProgress(100)
      setFetching(false)
      setTimeout(() => history.push('/dashboard'), 500)
    }
  }, [fetching, history, massStatus])

  return <LoadingContainer progress={progress} />
}

const mapStateToProps = state => ({
  hydrated: state.hydrated,
  massStatus: state.massStatus
})

const mapDispatchToProps = dispatch => ({
  fetchMasses: (creds) => dispatch(fetchMasses(creds))
})

export default connect(mapStateToProps, mapDispatchToProps)(FetchPage)
