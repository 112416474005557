import { getPelloniaApiUrl } from 'utils/CommonFunctions'
import UUID from 'utils/UUID'

/**
 * Attempts to send a patch of the installation to the API. Uses offline mode
 * to provide for commit and rollback functionality, based on the network
 * response and network availability.
 *
 * @param {*} effect The effect, the new state, of the installation
 * @param {*} uuid A unique identifier for the patch
 */
export const patchInstallation = (effect, uuid = UUID()) => ({
  type: 'installation/patch',
  payload: { effect: effect, uuid: uuid },
  meta: {
    offline: {
      // The network action to execute:
      effect: {
        json: effect,
        method: 'PATCH',
        url: `${getPelloniaApiUrl()}installations/api/installations/${effect.id}`
      },
      // Action to dispatch when effect succeeds:
      commit: { type: 'installation/patch/commit', meta: { uuid } },
      // Action to dispatch when effect has failed permanently:
      rollback: { type: 'installation/patch/rollback', meta: { uuid } }
    },
    uuid: UUID()
  }
})
