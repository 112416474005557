import React from 'react'
import BaseStepper from '../BaseStepper'
import {
  ConfirmationStep,
  CustomerStep,
  DeviceStep,
  PropertyStep
} from '../steps'

function EnvosenseStepper ({
  activeStep = 0,
  installation,
  onBack,
  onChange,
  onFinish,
  onNext,
  onStepChange
}) {
  const [, setFinished] = React.useState()
  const [valid, setValid] = React.useState(false)

  const handleFinish = () => {
    setFinished(true)
    onFinish?.call()
  }

  const handleValid = valid => {
    setValid(valid)
  }

  return (
    <BaseStepper
      activeStep={activeStep}
      onBack={onBack}
      onFinish={handleFinish}
      onNext={onNext}
      onStepChange={onStepChange}
      title='Envosense'
      valid={valid}
    >
      {[
        <PropertyStep
          activeStep={activeStep}
          key={0}
          installation={installation}
          onChange={onChange}
          onValid={handleValid}
        />,
        <CustomerStep
          activeStep={activeStep}
          key={1}
          installation={installation}
          onChange={onChange}
          onValid={handleValid}
        />,
        <DeviceStep
          activeStep={activeStep}
          key={5}
          installation={installation}
          onChange={onChange}
          onValid={handleValid}
        />,
        <ConfirmationStep
          activeStep={activeStep}
          key={6}
          installation={installation}
          onChange={onChange}
          onValid={handleValid}
        />
      ]}
    </BaseStepper>
  )
}

export default EnvosenseStepper
