import { offline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults'
import {
  applyMiddleware,
  compose,
  createStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit'
import massesSlice from './masses/massesSlice'
import {
  envosenseReducer,
  installationReducer,
  patchReducer,
  persistReducer
} from './reducers'
import reduceReducers from './utilities/reduceReducers'
import { getCredential } from 'providers/auth'

const DEFAULT_ERR_STATUS = 503
const BLANK_RESPONSE = 'No response received'

/**
 * If Redux DevTools is installed, then this is the one-line magic which
 * configures it. Highly recommended for inspecting and debugging Redux store
 * actions and state.
 *
 * @see https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

/**
 * Custom error class for handling HTTP errors. This is used by the effect
 * function to pass the HTTP status code back to the discard function, which
 * can then use that to determine whether to retry or rollback.
 */
class HttpError extends Error {
  constructor (message, status) {
    super(message)
    this.name = 'HttpError'
    this.status = status
  }
}

const decaySchedule = [
  1000 * 5, // After 5 seconds
  1000 * 5, // After 5 seconds
  1000 * 5, // After 5 seconds
  1000 * 15, // After 15 seconds
  1000 * 15, // After 15 seconds
  1000 * 15, // After 15 seconds
  1000 * 30, // After 30 seconds
  1000 * 30, // After 30 seconds
  1000 * 60 * 2, // After 2 minutes
  1000 * 60 * 5 // After 5 minutes
];

/**
 * Handles the known errors raised from the effect function and rethrows them as
 * HttpError to be caught and handled by the discard function.
 * Unknown errors are still thrown as normal.
 */
const handleResponseError = (err) => {
  const { name, message} = err
  console.error(message)
  if (name === 'SyntaxError') {
    throw new HttpError('Invalid JSON response from pellonia', DEFAULT_ERR_STATUS)
  } else if (name === 'TypeError') {
    throw new HttpError(message, DEFAULT_ERR_STATUS)
  } else {
    throw err
  }
}

/**
 * Custom method for handling redux-offline effects and sending the
 * effect data on to pellonia. This allows us to attach the current auth
 * token to the request at the time it's sent, rather than when it's created.
 */
const effect = async (effect, _action) => {
  const auth = effect.skipAuthorization
    ? {}
    : { Authorization: `Bearer ${getCredential()}` }
  const headers = { ...auth, ...effect.headers }

  try {
     const res = await fetch(effect.url, {
      body: effect.json && JSON.stringify(effect.json),
      method: effect.method,
      headers
    })
    const jsonBody = await res.json()
    const reason = jsonBody?.reason
    if (!res.ok) {
      throw new HttpError(reason ?? BLANK_RESPONSE, res.status)
    }
    return jsonBody
  } catch (err) {
    handleResponseError(err)
  }
}

/**
 * custom discard handler; this function dictates what we do with
 * `effects` that didn't work
 * @param {} error - error thrown by the failed `effect`
 * @returns - boolean - false will retry effect, true to discard it and trigger
 * a rollback
 */
const discard = async (error, _action, _retries) => {
  // not a network error -> discard
  if (!('status' in error)) {
    return true
  }

  // we want to trigger retry (return false) for
  // 401 errors and 5xx errors
  // for any other 4xx errors, we'll trigger a rollback (return true)
  return (error.status >= 400 && error.status < 500)
}

/**
 * Gets the Redux data store for use in the app. In the bundled app, this should
 * be treated as a singleton, but it can be called from anywhere and injected
 * for use in tests and stories, to give context when working with granular
 * components.
 *
 * ## Key concepts
 *
 * ### Reducers
 *
 * Reducers are functions that take the current state and an action as
 * arguments, and return a new state result. This root reducer function is
 * responsible for handling all of the actions that are dispatched, and
 * calculating what the entire new state result should be every time.
 *
 * ### Slices
 *
 * A "slice" is a collection of Redux reducer logic and actions for a single
 * feature in the app, typically defined together in a single file. The name
 * comes from splitting up the root Redux state object into multiple "slices"
 * of state.
 *
 * It's a more elegant way fo presenting Recux logic broken into areas of
 * concern.
 *
 * @see https://redux.js.org/tutorials/essentials/part-2-app-structure#creating-the-redux-store
 *
 * ### Mutation
 *
 * Redux requires reducer functions to be pure and treat state values as
 * immutable. While this is essential for making state updates predictable and
 * observable, it can sometimes make the implementation of such updates awkward.
 *
 * Writing "mutating" reducers simplifies the code. It's shorter, there's less
 * indirection, and it eliminates common mistakes made while spreading nested
 * state. However, the use of Immer within `createReduce` does add some "magic",
 * and Immer has its own nuances in behavior.
 *
 * https://redux-toolkit.js.org/api/createReducer#direct-state-mutation
 *
 * ### Offline
 *
 * This uses some clever offline magic, provided by redux-offline.
 *
 * @see https://github.com/redux-offline/redux-offline
 *
 * @param {Object} config Additional config to be passed to the store.
 */
export const getStore = config =>
  createStore(
    reduceReducers(
      envosenseReducer,
      installationReducer,
      patchReducer,
      persistReducer,
      massesSlice
    ),
    composeEnhancers(
      applyMiddleware(...getDefaultMiddleware()),
      offline({
        ...offlineConfig,
        ...config,
        effect,
        discard,
        retry: (_offlineAction, retries) => decaySchedule[retries] || null
      })
    )
  )

export default getStore
