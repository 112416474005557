import { TextField } from '@mui/material'
import React from 'react'

const RequiredTextField = React.memo(props => {
  const requiredValidator = React.useCallback(
    () => !props?.value || !props?.value?.length,
    [props.value]
  )

  return (
    <TextField
      {...props}
      error={requiredValidator(props.value) || props.error}
      fullWidth
      helperText={
        (requiredValidator(props.value) && 'This field is required') ||
        props.helperText
      }
      required
      variant='outlined'
    />
  )
})

export default RequiredTextField
