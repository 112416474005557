/**
 * The initial root state. This is required for reducers, so that they have some
 * context about the structure of itnended state, which eases a lot of pain when
 * reducers are called with `undefined` state (which is likely the first time
 * they run, before localstorage has been queried).)
 */
const initialState = {
  busy: false,
  hydrated: false,
  inbox: null,
  installationMap: {},
  masses: {},
  massesFlat: [],
  patches: {
    commit: [],
    effect: [],
    rollback: []
  }
}

export default initialState
