import React, { useEffect, useState, useMemo } from 'react'

const PageContext = React.createContext()
const PageDispatchContext = React.createContext()

const DEFAULT_PAGE_SIZE = 10

function PageProvider({ children }) {
  const [id, setId] = useState(0)
  const [filtered, setFiltered] = useState(false)
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [count, setCount] = useState(0)

  useEffect(() => {
    setPage(0)
  }, [pageSize, id])

  const dispatch = useMemo(() => ({
    setPage,
    setPageSize,
    setCount,
    setId,
    setFiltered
  }), [setPage, setPageSize, setCount, setId])

  const numPages = Math.ceil(count / pageSize)

  const value = {
    page,
    pageSize,
    count,
    numPages,
    filtered
  }

  return (
    <PageDispatchContext.Provider value={dispatch}>
        <PageContext.Provider value={value}>{ children }</PageContext.Provider>
    </PageDispatchContext.Provider>
  )
}

function usePageNumber() {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error('usePageNumber must be used within a PageProvider')
  }
  const { page } = context
  return page
}

function useNumPages() {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error('useNumPages must be used within a PageProvider')
  }
  const { numPages } = context
  return numPages
}

function useNumItems() {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error('useNumItems must be used within a PageProvider')
  }
  const { count } = context
  return count
}

function useFiltered() {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error('useFiltered must be used within a PageProvider')
  }
  const { filtered } = context
  return filtered
}

function usePageSize() {
  const context = React.useContext(PageContext)
  if (context === undefined) {
    throw new Error('usePageSize must be used within a PageProvider')
  }
  const { pageSize } = context
  return pageSize
}

function usePageDispatch() {
  const context = React.useContext(PageDispatchContext)
  if (context === undefined) {
    throw new Error('usePageDispatch must be used within a PageProvider')
  }
  return context
}


export { PageProvider, usePageNumber, usePageDispatch, usePageSize, useNumPages, useNumItems, useFiltered }