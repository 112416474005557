import { useEffect } from 'react'

/**
 * Hook used to inject the Google API script used for Google OAuth functionality.
 */
export default function useLoadGsiScript ({
  onScriptLoadSuccess,
  onScriptLoadError
}) {

  useEffect(() => {
    const scriptTag = document.createElement('script')
    scriptTag.src = 'https://accounts.google.com/gsi/client'
    scriptTag.async = true
    scriptTag.defer = true
    scriptTag.onload = onScriptLoadSuccess
    scriptTag.onerror = onScriptLoadError
    document.body.appendChild(scriptTag)

    return () => {
      document.body.removeChild(scriptTag)
    }
  }, [onScriptLoadError, onScriptLoadSuccess])

  return null;
}
