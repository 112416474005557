/**
 * Simple curry function, to reduce multiple reducers into just one. Works by
 * returning a closure, which will then call all accumulated closures.
 */
const reduceReducers = (...reducers) => {
  return (state, action) => {
    const reducer = (accumulator, current) => current(accumulator, action)
    return reducers.reduce(reducer, state)
  }
}

export default reduceReducers
