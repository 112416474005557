import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { Status, Wrapper } from '@googlemaps/react-wrapper'
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress
} from '@mui/material'
import React from 'react'
import styles from './GoogleMap.module.css'

/**
 * Ensures a default indicator is shown when loading/failing. Unlikely the user
 * will ever see this, but is a useful fallback.
 */
const render = status => {
  if (status === Status.LOADING) return <div>{status} ...</div>
  if (status === Status.FAILURE) return <div>{status} ...</div>
  return null
}

/**
 * The Google Map component. Should only be rendered when the Google APIs have
 * been loaded, so gets used in tandem with <Wrapper />.
 */
const GoogleMapComponent = ({
  center = window.google.maps.LatLngLiteral,
  locations = [],
  zoom = 1
}) => {
  const ref = React.useRef()
  const clusterRef = React.useRef()
  const mapRef = React.useRef()
  const [infoWindow, setInfoWindow] = React.useState()
  const [markers, setMarkers] = React.useState()

  /**
   * Create a map, and reusuable info window, which will display content when
   * a marker is pressed.
   */
  React.useEffect(() => {
    if (ref.current && !mapRef.current) {
      mapRef.current = new window.google.maps.Map(ref.current, { center, zoom })
      clusterRef.current = new MarkerClusterer({ map: mapRef.current })
      setInfoWindow(new window.google.maps.InfoWindow({ content: '' }))
    }
  }, [center, zoom])

  /**
   * Creates map markers, based on the location data, and adds a `click`
   * listener. Markers get encapsulated in a Cluster too.
   */
  React.useEffect(() => {
    const markers = locations
      .map((location, index) => {
        /** IF there's no location object, we have nothing to work with. */
        if (!location.location) return false

        const marker = new window.google.maps.Marker({
          position: location.location,
          label: (index + 1).toString(),
          title: location.title
        })

        marker.addListener('click', () => {
          infoWindow.setContent(location.title)
          infoWindow.open(mapRef.current, marker)
        })

        return marker
      })
      /** Filter out any empty markers. */
      .filter(marker => marker)

    /** Clears any previous markers, and adds new ones */
    clusterRef.current.clearMarkers()
    clusterRef.current.addMarkers(markers)
    setMarkers(markers)
  }, [infoWindow, locations])

  /**
   * Set the center of the map to the first marker.
   */
  React.useEffect(() => {
    if (!markers?.length) return
    mapRef.current?.setCenter(markers[0].getPosition())
  }, [markers])

  return <div className={styles.root} ref={ref} />
}

/**
 * Display a Google Map instance, using the official maps API. Uses a Wrapper,
 * so that maps only get shown once the Google API has been loaded.
 */
const GoogleMap = ({
  collection = {},
  locations = [],
  mapReady = false
}) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

  const center = { lat: 54.377562051425606, lng: -2.9057189727514308 }
  const zoom = 10

  return (
    <Card role='application'>
      <CardHeader title={collection?.title} />

      <Divider />

      {(mapReady && (
        <Wrapper apiKey={apiKey} render={render}>
          <GoogleMapComponent
            center={center}
            locations={locations}
            zoom={zoom}
          />
        </Wrapper>
      )) || (
        <CardContent>
          <div className={styles.progress}>
            Gathering map information. This can sometimes take a little while.
          </div>
          <LinearProgress />
        </CardContent>
      )}
    </Card>
  )
}

export default GoogleMap
