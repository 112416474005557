export const tiers = {
  HP_FULL: 0,
  HP_PLUS: 1,
  HP_LITE: 2,
  ENVOSENSE: 3
}

export const tierToString = tier => {
  switch (tier) {
    case tiers.HP_LITE:
      return 'HPL'
    case tiers.HP_FULL:
      return 'HP'
    case tiers.HP_PLUS:
      return 'HP+'
    case tiers.ENVOSENSE:
      return 'ENV'
    default:
      return 'HP'
  }
}
