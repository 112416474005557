import { CheckCircle } from '@mui/icons-material'
import { Grid, InputAdornment, TextField } from '@mui/material'
import { PhoneTextField } from 'components/inputs'

/**
 * A form for collecting data relating to a Touch device. Includes an extra CLI
 * field to verify the correct MSISDN has been entered.
 */
const ContactHubTouchForm = ({ device, index, onChange }) => {
  
  const validMsisdn =
    device?.verifyMsisdn === device?.msisdn && (device?.msisdn?.length >0)

  const validAdornment = validMsisdn && (
    <InputAdornment position='end'>
      <CheckCircle color='success' />
    </InputAdornment>
  )

  /**
   * the event object is set not to take its default action unless explicitly handled,
   * used to the prevent the paste command from doing so. This forces the user to
   * type the touchscreen number into the verify box
   */

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <Grid container item spacing={2}>
      <Grid item sm={6} xs={12}>
        <PhoneTextField
          id={`msisdn${index}`}
          InputProps={{
            endAdornment: validAdornment
          }}
          name='msisdn'
          label='Touchscreen number'
          onChange={onChange}
          required
          value={device?.msisdn || ''}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <PhoneTextField
          id={`verifyMsisdn${index}`}
          error={!validMsisdn}
          helperText={!validMsisdn && 'Touchscreen numbers should be the same'}
          InputProps={{
            endAdornment: validAdornment
          }}
          name='verifyMsisdn'
          label='Verify Touchscreen number'
          onChange={onChange}
          onPaste={handleChange}
          required
          value={device?.verifyMsisdn || ''}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          helperText='E.g. O2, Vodafone'
          id={`personalMobileProvider${index}`}
          name='personalMobileProvider'
          label='Personal mobile provider'
          onChange={onChange}
          value={device?.personalMobileProvider || ''}
          variant='outlined'
        />
      </Grid>
    </Grid>
  )
}

export default ContactHubTouchForm
