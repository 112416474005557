import '@fontsource/roboto'
import { AuthProvider, useIsSignedIn } from 'providers/auth'
import rg4js from 'raygun4js'
import React, { useEffect } from 'react'
import AuthenticatedApp from './app/AuthenticatedApp'
import UnauthenticatedApp from './app/UnauthenticatedApp'
import AppProviders from './providers'
import store from 'app/store/getStore'
import { Provider } from 'react-redux'
import { useAuth } from './providers/auth'
import { PageProvider } from './providers/PageProvider'

/**
 * A higher order component for the whole app. Rather than trying to derive
 * protected routing based on the authentication state, we simplify things by
 * providing two distinct versions of the app. This provides for a simpler
 * security model which prevents the creation of attack vectors caused by
 * misconfiguration or miscomprehension.
 *
 * The only peculiarity here is that everything gets wrapped by `App`. This is
 * so that `AuthProvider` can provide the context for the `useAuth` hook.
 */
const BareApp = () => {
  const { user } = useAuth()
  const isSignedIn = useIsSignedIn()

  /**
   * Raygun user setting. Raygun is monitoring tool which is used by this
   * app for crash reporting and performance monitoring. The app sends the
   * Raygun app performance data constantly and error data whenever the
   * app encounters one. The user identifier property allows us to track
   * which installer app user has encountered a particular error or
   * performance issue.
   */
  useEffect(() => {
    if (process.env.REACT_APP_RAYGUN_ENABLED === 'true') {
      rg4js('setUser', { identifier: user?.name })
    }
  }, [user, isSignedIn])

  return (
    <AppProviders>
      {isSignedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </AppProviders>
  )
}

const clientId = process.env.REACT_APP_CLIENT_ID

const App = () => {
  return (
    <Provider store={store()}>
      <AuthProvider clientId={clientId}>
        <PageProvider>
          <BareApp />
        </PageProvider>
      </AuthProvider>
    </Provider>
  )
}

export default App
