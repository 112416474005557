import { Grid, TextField } from '@mui/material'
import { PhoneTextField } from 'components/inputs'

/**
 * Form for collection of Contact Hub Classic data.
 */
const ContactHubClassicForm = ({ device, index, onChange }) => {
  return (
    <Grid container item spacing={2}>
      <Grid item sm={6} xs={12}>
        <PhoneTextField
          id={`msisdn${index}`}
          name='msisdn'
          label='Contacthub number'
          onChange={onChange}
          required
          value={device?.msisdn || ''}
        />
      </Grid>

      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          helperText='E.g. O2, Vodafone'
          id={`personalMobileProvider${index}`}
          name='personalMobileProvider'
          label='Personal mobile provider'
          onChange={onChange}
          value={device?.personalMobileProvider || ''}
          variant='outlined'
        />
      </Grid>
    </Grid>
  )
}

export default ContactHubClassicForm
