import { Box, Divider } from '@mui/material'
import React from 'react'
import rg4js from 'raygun4js'

/**
 * Provides an error boundary to capture any exceptions which bubble up through
 * the component tree. Just a nice way to handle errors in a consistent fashion.
 *
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: null }
  }

  /**
   * Update state so the next render will show the fallback UI.
   */
  componentDidCatch (error, errorInfo) {
    this.setState({ error: error, errorInfo: errorInfo })
    if (process.env.REACT_APP_RAYGUN_ENABLED === 'true') {
      rg4js('send', {
        error: error,
        customData: [errorInfo]
      })
    }
  }

  /**
   * Explicit error state.
   */
  get hasError () {
    return this.state.error
  }

  render () {
    if (this.hasError) {
      return (
        <Box m={3}>
          <h1>Something went wrong</h1>
          <p>The app has encountered an unexpected problem.</p>

          <Divider />

          <small>
            <pre>
              {this.state.error.toString()}
              {this.state.errorInfo.componentStack}
            </pre>
          </small>
        </Box>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
