import React from 'react'
import { useLocation } from 'react-router-dom'

const exclusions = [/step\/\d+/i]

/**
 * A simple component which will ensure the window scrolls back to the top of
 * each page when navigating between locations.
 *
 * Includes an optional array of regexp exclusions, which is intended to be used
 * when the path might change, but the context of the page remains the same.
 */
export default function ScrollToTop () {
  const [path, setPath] = React.useState('')
  const { pathname } = useLocation()

  React.useEffect(() => {
    const exclude = exclusions.some(e => e.test(path) && e.test(pathname))
    setPath(pathname)
    !exclude && window?.scrollTo(0, 0)
  }, [path, pathname])

  return null
}
