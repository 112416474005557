import React from 'react'
import { connect } from 'react-redux'

/**
 * Displays a summary of the number of installation patches which are currently
 * queued as offline, and waiting to be dispatched.
 */
const OfflineOutboxStatus = ({ patches = {} }) => {
  const pluralize = noun => `${noun}${patches.effect?.length === 1 ? '' : 's'}`

  return (
    <>
      {patches.effect?.length || 0} {pluralize('update')} to sync
    </>
  )
}

const mapStateToProps = state => ({
  patches: state.patches
})

const mapDispatchToProps = dispatch => ({})

export { OfflineOutboxStatus }
export default connect(mapStateToProps, mapDispatchToProps)(OfflineOutboxStatus)