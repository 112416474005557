import { createReducer } from '@reduxjs/toolkit'
import initialState from './initialState'
import {
  findPatchEffect,
  findPatchEffectIndex,
  findPatchRollback,
  findPatchRollbackIndex
} from '../selectors'

/**
 * Reducer which handles actions for attaching envosense devices to accounts.
 */
export const envosenseReducer = createReducer(initialState, builder => {
  builder

    /**
     * Runs every time a new envosense request is made, create the envosense object
     * in the Redux store if it doesn't already exist.
     */
    .addCase('envosense/patch', (state, action) => {
      const uuid = action.payload.uuid

      /**
       * Remove patch from the effect queue if it already exists
       */
      state.patches.effect = state.patches.effect.filter(
        element => element.uuid !== uuid
      )

      /**
       * If the envosense patch already exists on the rollback queue, we extract it
       * and place it onto the effects queue. Prevents us ending up with
       * orphaned duplicates.
       *
       * If the envosense patch isn't on the rollback queue, then a new one is
       * created.
       */
      const item = findPatchRollback(state, uuid)
        ? state.patches.rollback
            .splice(findPatchRollbackIndex(state, uuid), 1)
            .at(0)
        : {
            type: 'Envosense',
            createdAt: new Date().toISOString(),
            address: action.payload.address,
            fullName: action.payload.fullName,
            installationId: action.payload.installationId,
            effect: action.payload.effect,
            uuid
          }

      /**
       * To be safe and to keep things tidy, remove any items from the rollback queue with
       * a matching installation ID.
       */
      state.patches.rollback = state.patches.rollback.filter(
        element =>
          !(
            element.installationId === action.payload.installationId &&
            element.type === 'Envosense'
          )
      )

      /**
       * Put the item on the effect queue.
       */
      state.patches.effect.push(item)
    })

    /**
     * Runs when a patch has been successful. We delete the patch from the state
     * because it's no longer needed and to keep the state clean. If we need use successful
     * envosense patches in the future, it's easy to update the status here to be 'success'.
     */
    .addCase('envosense/patch/commit', (state, action) => {
      const uuid = action.meta.uuid

      /**
       * Make sure we pull the effect from the effect queue, if it currently
       * lives there (likely), and then place onto the final commit queue.
       */
      if (findPatchEffect(state, uuid)) {
        const effect = state.patches.effect.splice(
          findPatchEffectIndex(state, uuid),
          1
        )
        state.patches.commit.push({
          type: 'Envosense',
          createdAt: new Date().toISOString(),
          address: effect.at(0).address,
          fullName: effect.at(0).fullName,
          installationId: effect.at(0).installationId,
          effect: effect.at(0).effect,
          uuid
        })
      }
    })

    /**
     * Runs when Redux offline rolls a patch back,  either because a rollback
     * status code was returned from the server or a retry status code was
     * returned and retry schedule finished.
     */
    .addCase('envosense/patch/rollback', (state, action) => {
      const uuid = action.meta.uuid

      /**
       * The patch should previously exist on the effect queue, so we grab it
       * from there and then place on the rollback queue.
       */
      if (findPatchEffect(state, uuid)) {
        const effect = state.patches.effect.splice(
          findPatchEffectIndex(state, uuid),
          1
        )

        state.patches.rollback.push({
          type: 'Envosense',
          createdAt: new Date().toISOString(),
          address: effect.at(0).address,
          fullName: effect.at(0).fullName,
          installationId: effect.at(0).installationId,
          effect: effect.at(0).effect,
          uuid
        })
      }
    })

    /**
     * Be a good citizen and at the very least, pass back the state.
     */
    .addDefaultCase((state, action) => state)
})
