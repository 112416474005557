import { CloudDone, CloudOff, Menu } from '@mui/icons-material'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link as RouterLink, Route, Switch } from 'react-router-dom'
import { AppointmentToolbar, NavDrawer } from '../../molecules'
import BackButton from '../BackButton'

const useStyles = makeStyles(theme => ({
  root: {},
  info: {
    '& .MuiBadge-badge': {
      backgroundColor: theme.palette.info.main
    }
  },
  title: {
    ...theme.typography.h6,
    flexGrow: 1
  },
  sync: {
    marginLeft: '32px',
    border: '1px solid rgba(255, 255, 255, 0.05)'
  },
  offline: {
    backgroundColor: 'rgba(255, 100, 100, 0.75) !important'
  },
  online: {
    backgroundColor: 'rgba(255, 255, 255, 0.05) !important'
  }
}))

/**
 * The main application bar provides navigational context, along with access to
 * an app drawer for navigation.
 */
const NavBar = ({ actions, open: isOpen = false, title }) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(isOpen)
  const offline = useSelector(state => state.offline)

  const onlineIcon = React.useMemo(
    () => (offline.online ? <CloudDone /> : <CloudOff />),
    [offline.online]
  )

  const onlineStyle = React.useMemo(
    () => (offline.online ? classes.online : classes.offline),
    [classes.offline, classes.online, offline.online]
  )

  /**
   * Toggles the app drawer open or closed.
   */
  const toggleDrawer = React.useCallback(
    (e, o) => {
      if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
        return
      }
      setOpen(o)
    },
    [setOpen]
  )

  const menuButton = React.useMemo(
    () => (
      <IconButton
        aria-label='menu'
        color='inherit'
        edge='start'
        onClick={e => toggleDrawer(e, true)}
        size='large'
      >
        <Menu />
      </IconButton>
    ),
    [toggleDrawer]
  )

  return (
    <div className={classes.root}>
      <NavDrawer onClose={e => toggleDrawer(e, false)} open={open} />

      <AppBar position='fixed'>
        <Toolbar>
          <Switch>
            <Route
              path={[
                '/mass/:massId/collection/:collectionId/engineering/:installationId',
                '/mass/:massId/collection/:collectionId/installation/:installationId/step/:stepId',
                '/mass/:massId/collection/:collectionId/installation/:installationId'
              ]}
            >
              <BackButton /> <div className={classes.title} />{' '}
              <AppointmentToolbar />
            </Route>
            <Route
              path={[
                '/mass/:massId/collection/:collectionId',
                '/mass/:massId/collection',
                '/mass/:massId',
                '/mass'
              ]}
            >
              <BackButton />
              <div className={classes.title} />
            </Route>
            <Route path='/sync'>
              {menuButton}
              <div className={classes.title}>Your saved data</div>
            </Route>
            <Route path='/dashboard'>
              {menuButton}
              <div className={classes.title}>Dashboard</div>
            </Route>
          </Switch>
          <div className={classes.title} />

          <IconButton
            className={[onlineStyle].join(' ')}
            color='inherit'
            component={RouterLink}
            to='/sync'
            size='large'
          >
            {onlineIcon}
          </IconButton>
        </Toolbar>
      </AppBar>

      <Toolbar />
    </div>
  )
}

export default NavBar
