import Notification from './Notification'

/**
 * A simple class which dispatches notification events to any object which has
 * event listeners added to it. Ordinarily, React Context would be used for the
 * passing of state throughout the app, but it's not a great fit for allowing
 * native, non-React classes to talk to one another.
 *
 * The module exposes a singleton instance of the `NotificationManager`. This
 * means it remains a single source for dispatching and listening to events.
 * It leaves the implementation of any contextual logic to the objects observing
 * it. It's also worth noting that since this can be referenced from various
 * contexts, it implements some strict typechecking, and references should try
 * to include a try-catch statement.
 */
class NotificationManager extends window.EventTarget {
  /**
   * Add a notification. Throws a TypeError if the paramater is not of the right
   * type.
   * @param {Notification} notification The Notification to add
   */
  addNotification (notification) {
    if (!(notification instanceof Notification)) {
      throw new TypeError(
        'The notification parameter expects a type of Notification. This notification will not be broadcast.'
      )
    }
    this.dispatchEvent(
      new window.CustomEvent('onadd', { detail: notification })
    )
    if (process.env.NODE_ENV === 'development') {
      console.groupCollapsed(`Notification: ${notification.detail}`)
      console.log(notification)
      console.groupEnd()
    }
  }

  removeNotification (id) {
    this.dispatchEvent(new window.CustomEvent('onremove', { detail: id }))
  }
}

const manager = new NotificationManager()
export default manager
