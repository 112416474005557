import { createSelector } from '@reduxjs/toolkit'

/**
 * These selectors provide an easy way to compute derived data. They are
 * composable, and are only recomputed when one of their arguments changes.
 */

const address1Selector = state => state?.account?.customer.address1 || ''
const address2Selector = state => state?.account?.customer.address2
const townSelector = state => state?.account?.customer.town
const countySelector = state => state?.account?.customer.county || ''
const postcodeSelector = state => state?.account?.customer.postcode || ''

export const shortAddressSelector = createSelector(
  address1Selector,
  postcodeSelector,
  (address1, postcode) => `${address1} ${postcode}`
)

export const fullAddressSelector = createSelector(
  address1Selector,
  address2Selector,
  townSelector,
  countySelector,
  postcodeSelector,
  (address1, address2, town, county, postcode) =>
    [address1, address2, town, county, postcode].filter(el => el).join(', ')
)
