import { Grid, Step, StepContent, Switch, FormControlLabel, FormGroup, Checkbox } from '@mui/material'
import { TimeInput } from 'components/inputs'
import StepperButton from 'components/steppers/StepperButton'
import React, { useEffect } from 'react'
import { status, accountType } from 'constants/statuses'

/**
 * Step to collect the OKEachDay contact time details for the customer.
 */
const ContactTimesStep = ({
  activeStep,
  index,
  installation,
  onActiveStep,
  onChange = () => { },
  onValid,
  ...rest
}) => {
  const existingContactTimes = installation?.account?.type === accountType.HP_FULL &&
    installation?.account?.status === status.ACTIVE;

  const [dailyContact, setDailyContact] = React.useState(true);
  const [resetContactTimes, setResetContactTimes] = React.useState(false);
  const [reducedSchedule, setReducedSchedule] = React.useState(false);

  let setContactTimes = (dailyContact && !existingContactTimes) || (dailyContact && existingContactTimes && resetContactTimes)

  const defaultTime = new Date(0, 0, 0, 9, 0)
  const minTime = new Date(0, 0, 0, 8, 0)
  const maxTime = new Date(0, 0, 0, 22, 0)
  const daysOfWeek = ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
  const [checkedDays, setCheckedDays] = React.useState([]);

  const handleDayCheckboxChange = (day) => {
    setCheckedDays((prevCheckedDays) => {
      const updatedCheckedDays = prevCheckedDays.includes(day)
        ? prevCheckedDays.filter((d) => d !== day)
        : [...prevCheckedDays, day];

      const changeEvent = {
        target: {
          value: updatedCheckedDays,
        }
      }
      onChange('contactTimes.days', changeEvent);
      return updatedCheckedDays;
    });
  };

  const handleDailyContactChange = () => {
    setDailyContact((prev) => {
      const changeEvent = {
        target: {
          type: 'switch',
          value: !prev,
        },
      };
      onChange('dailyContact', changeEvent);

      if (prev) {
        setResetContactTimes(false);
        setReducedSchedule(false);
        onChange('contactTimes', null)
      }
      return !prev
    });
  };

  const handleResetContactTimesChange = () => {
    setResetContactTimes((prev) => {
      if (prev) {
        setReducedSchedule(false);
        onChange('contactTimes', null);
      }
      return !prev
    })
  }

  const handleReducedContactChange = () => {
    setReducedSchedule((prev) => {
      if (prev) {
        onChange('contactTimes.days', null);
      }
      return !prev
    })
  }

  /**
    * Checks if the contact time value falls within the Alertacall operational
    * hours before the user can progress onto the next step of the form as well
    * as making sure the primary contact time is eariler than the secondary
    * contact time.
    */
  useEffect(() => {
    if (activeStep === index) {
      let valid = false
      if ((!dailyContact) || (existingContactTimes && !resetContactTimes)) {
        valid = true
        onValid(valid)
        return
      }

      if(reducedSchedule && (checkedDays.length < 1)){
        valid = false
        onValid(valid)
        return
      }

      if (
        Date.parse(installation?.contactTimes?.primary) >=
        Date.parse(installation?.contactTimes?.secondary)
      ) {
        valid = false
        onValid(valid)
        return
      }

      const validTime = timeStamp =>
        /(0[89]|1[0-9]|2[0-2]):([03]0)/.test(
          String(timeStamp).substring(16, 21)
        )

      if (
        validTime(installation?.contactTimes?.primary) &&
        !installation?.contactTimes?.secondary
      ) {
        valid = true
      }

      if (
        validTime(installation?.contactTimes?.primary) &&
        validTime(installation?.contactTimes?.secondary)
      ) {
        valid = true
      }



      onValid(valid)
    }
  }, [activeStep, dailyContact, existingContactTimes, index,
    installation?.contactTimes?.primary, installation?.contactTimes?.secondary,
    onValid, resetContactTimes, reducedSchedule, checkedDays])

  return (
    <Step index={index} {...rest}>
      <StepperButton
        activeStep={activeStep}
        index={index}
        label='OkEachDay contact times'
        onActiveStep={onActiveStep}
      />
      <StepContent>
        <FormControlLabel
          control={<Switch checked={dailyContact} onChange={handleDailyContactChange} />}
          label="Does the occupant want the OK service?"
        />
        {existingContactTimes && dailyContact && (
          <Grid container spacing={2}>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={resetContactTimes} onChange={handleResetContactTimesChange} />}
                label="This account has previously been set up. Would you like to reset the contact times?" />
            </Grid>
          </Grid>
        )}
        {setContactTimes && (
          <Grid container spacing={2} style={{ marginTop: '3px', marginBottom: '5px' }}>
            <Grid item sm={3} xs={12}>
              <TimeInput
                defaultTime={defaultTime}
                id='primary'
                label='Primary contact time'
                maxTime={maxTime}
                minTime={minTime}
                onChange={value => onChange('contactTimes.primary', value)}
                required
                value={installation?.contactTimes?.primary}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <TimeInput
                defaultTime={defaultTime}
                id='sceondary'
                label='Second contact time'
                maxTime={maxTime}
                minTime={minTime}
                onChange={value => onChange('contactTimes.secondary', value)}
                value={installation?.contactTimes?.secondary}
              />
            </Grid>
          </Grid>
        )}
        {setContactTimes && (
          <Grid container spacing={2}>
            <Grid item>
              <FormControlLabel
                control={<Switch checked={reducedSchedule} onChange={handleReducedContactChange} />}
                label="Create reduced schedule?" />
            </Grid>
          </Grid>
        )}
        {reducedSchedule && setContactTimes && (
          <Grid style={{ marginTop: '5px' }}>
            <FormGroup aria-label='Days of week' row>
              {daysOfWeek.map((day) => (
                <FormControlLabel
                  key={day}
                  control={
                    <Checkbox
                      checked={checkedDays.includes(day)}
                      onChange={() => handleDayCheckboxChange(day)}
                    />
                  }
                  label={day.toUpperCase()}
                  labelPlacement='top'
                />
              ))}
            </FormGroup>
          </Grid>
        )}
      </StepContent>
    </Step>
  )
}

export default ContactTimesStep
