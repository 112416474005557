import { Button, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { Add } from '@mui/icons-material'
import { devices } from 'constants/devices'
import React from 'react'

/**
 * Simple component to select a device to add.
 *
 *✅ Should restrict choices based on the account tier.
 */
const AddDevice = ({ exclusive, onClick, onClose, onSelect }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
    onClick?.(event)
  }

  const handleClose = event => {
    setAnchorEl(null)
    onClose?.(event)
  }

  const handleSelect = event => {
    handleClose()
    onSelect?.(event)
  }

  return (
    <div>
      <Button
        id='menu-button'
        aria-controls='menu'
        aria-haspopup='true'
        color='secondary'
        onClick={handleClick}
        startIcon={<Add />}
        variant='contained'
      >
        Add a device
      </Button>

      <Menu
        anchorEl={anchorEl}
        id='menu'
        MenuListProps={{
          'aria-labelledby': 'menu-button'
        }}
        onClose={handleClose}
        open={open}
      >
        {devices.map(
          device =>
            (!exclusive || !device.exclusive) && (
              <MenuItem
                key={device.type}
                onClick={handleSelect}
                value={device.type}
              >
                <ListItemIcon>{device.icon}</ListItemIcon>
                {device.title}
              </MenuItem>
            )
        )}
      </Menu>
    </div>
  )
}

export default AddDevice
